import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Divider, Typography, IconButton, Menu, MenuItem, Skeleton } from "@mui/material";

import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditIcon from "@mui/icons-material/Edit";

import * as S from "../styles";
import { ManagerContext } from "views/LiveBoard/contexts/Manager";
import { DeleteModal } from "./Modal";

const Card = ({ item, timeline, reference, dragging, ...props }) => {
  const { gameId } = useParams();
  const baseURL = process.env.REACT_APP_LIVE_BOARD_SERVER_URL;
  const BOARD_CLOUDFRONT_URL = process.env.REACT_APP_LIVE_BOARD_CLOUDFRONT_URL;
  const { handleCardModal, handlePostModal, handleModifyTarget } = useContext(ManagerContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [targetId, setTargetId] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleMenu = (e) => {
    e?.stopPropagation();

    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleDeleteModal = (e) => {
    e.stopPropagation();
    handleMenu();
    setIsDeleteModalOpened(true);
    setTargetId(item.id);
  };

  const handleModifyPost = (e) => {
    e.stopPropagation();

    let target = { currentTarget: { id: item.section } };
    handlePostModal(target);
    handleModifyTarget(item);

    setAnchorEl(null);
  };

  return (
    <S.Card
      className={"prevent-select" + (props["sort-mode"] === "true" && " sort-mode")}
      ref={reference}
      {...props}
      onClick={handleCardModal}
      item-id={item.id}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          p: "2.5px",
        }}
        size="small"
        onClick={handleMenu}
      >
        <MoreVertIcon fontSize="10px" />
      </IconButton>

      <Menu disablePortal anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenu}>
        <MenuItem onClick={handleModifyPost} id={targetId}>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <EditIcon />

            <Typography sx={{ fontWeight: "600" }}>게시물 수정</Typography>
          </Box>
        </MenuItem>

        <MenuItem onClick={handleDeleteModal}>
          <Box sx={{ display: "flex", gap: "10px", color: "#f00", alignItems: "center" }}>
            <DeleteForeverOutlinedIcon fontSize="small" />

            <Typography fontWeight={600}>게시물 삭제</Typography>
          </Box>
        </MenuItem>
      </Menu>

      {isDeleteModalOpened && (
        <DeleteModal
          open={isDeleteModalOpened}
          onClose={(e) => {
            e?.stopPropagation();
            setIsDeleteModalOpened(false);
            setTargetId(null);
          }}
          targetId={targetId}
        />
      )}

      {/* {thumbnail !== null ? ( */}
      <Typography className="card-title" sx={{ wordBreak: "break-all" }}>
        {item.title}
      </Typography>
      {/* ) : (
        <Skeleton variant="text" width="100%" height="20px" />
      )} */}

      {item.file && (
        <Box className="card-image">
          {/* {thumbnail !== null && thumbnail !== undefined ? ( */}
          <Box sx={{ position: "relative" }}>
            <img
              src={`${BOARD_CLOUDFRONT_URL}/${gameId}/${item.id}/thumbnail.webp?v=${
                item.version || 0
              }`}
              onLoad={() => {
                setTimeout(() => {
                  setImageLoaded(true);
                }, 250);
              }}
              style={{
                maxHeight: timeline ? "350px" : "400px",
                objectFit: "contain",
                opacity: imageLoaded ? 1 : 0,
              }}
            />

            <Box
              sx={{
                position: "absolute",
                bottom: "4px",
                left: 0,
                bgcolor: "#333",
                color: "#fff",
                p: "2px 5px",
                borderRadius: "0 5px 0 0",
                font: "700 14px Pretendard",
              }}
            >
              {item.file.type?.toUpperCase() || item.file.name.split(".").pop().toUpperCase()}
            </Box>

            {!imageLoaded && (
              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  zIndex: 2,
                  top: 0,
                  opacity: imageLoaded ? 0 : 1,
                }}
              />
            )}
          </Box>
          {/* ) : (
            <Skeleton variant="rectangular" width="100%" height="200px" />
          )} */}

          {/* {thumbnail !== null ? ( */}
          <>
            {item.link && <Typography noWrap>{item.link}</Typography>}
            {!item.link && <Typography noWrap>{item.file.name}</Typography>}
          </>
          {/* // ) : (
          //   <Skeleton variant="text" width="100%" height="10px" />
          // )} */}
        </Box>
      )}

      {/* {thumbnail !== null ? ( */}
      <Typography className="card-content">{item.explain}</Typography>
      {/* ) : (
        <Skeleton variant="text" width="100%" height="40px" />
      )} */}

      <Divider />

      {/* {thumbnail !== null ? ( */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box className="card-footer">
          <FavoriteIcon sx={{ color: "#c33c3c" }} />

          <Typography>{item.likes?.length || 0}</Typography>
        </Box>

        <Box sx={{ display: "flex", gap: "5px", alignItems: "center", pr: "5px" }}>
          <ChatBubbleOutlineIcon fontSize="large" sx={{ scale: "0.6", color: "#555" }} />
          <Typography>{item.comments?.length || 0}</Typography>
        </Box>
      </Box>
      {/* ) : (
        <Skeleton variant="rectangular" width="100%" height="40px" />
      )} */}
    </S.Card>
  );
};

export default Card;
