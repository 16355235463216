import axios from "axios";

export const sendContact = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/contact", data)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
