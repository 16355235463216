import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { PdfViewer } from "@naverpay/react-pdf";

import {
  Modal,
  Box,
  IconButton,
  Typography,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from "@mui/icons-material/Download";

import { UserContext } from "views/LiveBoard/contexts/User";
import { SnackBarContext } from "components/SnackBar/ContextAPI";
import { Textfield } from "components/Textfield";
import { CustomLoadingButton as LoadingButton } from "components/Button";

import * as API from "utils/api/Board";
import axios from "axios";

const baseURL = process.env.REACT_APP_LIVE_BOARD_SERVER_URL;
// const baseURL = "http://192.168.0.87:3004/live-board";
// const baseURL = "http://172.30.1.52:3004/live-board";
const BOARD_CLOUDFRONT_URL = process.env.REACT_APP_LIVE_BOARD_CLOUDFRONT_URL;

export const CardModal = (props) => {
  const { open, onClose } = props;
  const { gameId } = useParams();
  const { selectedCard, setSelectedCard, socket, layout, board, handleCardModify } =
    useContext(UserContext);
  const SnackBar = useContext(SnackBarContext);
  const uuid = localStorage.getItem("uuid");
  const ref = useRef(null);

  //   console.log(selectedCard);

  const [time] = useState(new Date());

  const [opacity, setOpacity] = useState(0);
  const [postList, setPostList] = useState([]);
  const [listCount, setListCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isImageModalOpened, setIsImageModalOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

  const [comment, setComment] = useState("");
  const [replyId, setReplyId] = useState(null);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [name, setName] = useState("");
  const [src, setSrc] = useState(null);

  const [file, setFile] = useState(null);
  const [renderType, setRenderType] = useState(null);
  const [iframeOpacity, setIframeOpacity] = useState(0);

  const selectBackCard = () => {
    setFile(null);
    setSelectedCard(postList[currentIndex - 1]);
    setCurrentIndex(currentIndex - 1);
  };

  const selectForwardCard = () => {
    setFile(null);
    setSelectedCard(postList[currentIndex + 1]);
    setCurrentIndex(currentIndex + 1);
  };

  const addComment = () => {
    if (!comment) return;
    if (comment.trim() === "") {
      setComment("");
      return;
    }

    let user;

    if (isAnonymous || name.trim() === "") {
      user = "익명";
    } else {
      user = name;
    }

    if (replyId) {
      socket.emit(
        "addReply",
        { gameId, postId: selectedCard.id, comment, user, commentId: replyId },
        (res) => {
          if (res.success) {
            setComment("");
            setReplyId(null);
            setName("");

            SnackBar.setOpen(true);
            SnackBar.setSeverity("success");
            SnackBar.setText("답글이 등록되었습니다.");
          }
        }
      );

      return;
    }

    socket.emit("addComment", { gameId, postId: selectedCard.id, comment, user }, (res) => {
      if (res.success) {
        setComment("");

        SnackBar.setOpen(true);
        SnackBar.setSeverity("success");
        SnackBar.setText("댓글이 등록되었습니다.");
      }
    });
  };

  const handleLikes = (e) => {
    e.stopPropagation();

    socket.emit("handleLike", { gameId, postId: selectedCard.id, userId: uuid });
  };

  const handleImageModal = (e) => {
    // const imageId = e.target.getAttribute("image-id");

    setIsImageModalOpened(!isImageModalOpened);
  };

  const handleReply = (e) => {
    // console.log("reply");
    setReplyId(e.currentTarget.id);
    // ref.current.focus();
    ref.current.click();
  };

  const downloadFile = () => {
    const type = selectedCard.file.type;

    API.downloadFile(
      { gameId, fileId: selectedCard.id, type, fileName: selectedCard.file.name },
      (res) => {
        const { success } = res.data;

        if (!success) {
          alert("파일을 다운로드하는데 실패했습니다.");
          window.location.reload();
          return;
        }

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const a = document.createElement("a");

        a.href = url;
        a.download = selectedCard.file.name;
        a.click();

        a.remove();
      }
    );
  };

  const modifyCard = () => {
    const password = window.prompt("비밀번호를 입력해주세요.");

    if (!password) return;

    if (password === selectedCard.password) {
      handleCardModify(selectedCard);
      onClose();
    } else {
      SnackBar.setOpen(true);
      SnackBar.setText("비밀번호가 틀렸습니다.");
      SnackBar.setSeverity("error");
    }
  };

  const deleteCard = () => {
    const password = window.prompt("비밀번호를 입력해주세요.");

    if (!password) return;

    if (password === selectedCard.password) {
      handleDeleteModal();
    } else {
      SnackBar.setOpen(true);
      SnackBar.setText("비밀번호가 틀렸습니다.");
      SnackBar.setSeverity("error");
    }
  };

  const handleDeleteModal = () => {
    setIsDeleteModalOpened((prev) => !prev);
  };

  useEffect(() => {
    if (open) {
      setOpacity(1);
    } else {
      setOpacity(0);
    }
  }, []);

  // 192.168.0.154
  useEffect(() => {
    if (!selectedCard || !selectedCard.file) {
      setSrc(null);
      setFile(null);
      setRenderType(null);
      setIframeOpacity(0);
      return;
    }

    switch (selectedCard.file.type.toUpperCase()) {
      case "PPTX":
      case "PPT":
      case "XLSX":
      case "XLS":
      case "DOCX":
      case "DOC":
        const fileUrl = `https://www.withplus.live/file?postId=${
          selectedCard.id
        }&gameId=${gameId}&type=${selectedCard.file.type}&filename=${
          selectedCard.file.name
        }&time=${time.getTime()}`;
        const encodedUrl = encodeURIComponent(fileUrl); // URL 인코딩
        const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`;

        setFile({ name: selectedCard.file.name, src: viewerUrl, type: selectedCard.file.type });
        setRenderType("iframe");
        break;

      case "PDF":
      case "HWP":
      case "HWPX":
        const pdfUrl = `https://www.withplus.live/file?postId=${selectedCard.id}&gameId=${gameId}&type=${selectedCard.file.type}&filename=${selectedCard.file.name}`;

        setFile({ name: selectedCard.file.name, src: pdfUrl, type: selectedCard.file.type });
        setRenderType("pdf");
        break;

      default:
        // fetch(`${baseURL}/thumbnail?id=${selectedCard.id}&gameId=${gameId}`).then((res) => {
        //   res.blob().then((blob) => {
        //     const url = URL.createObjectURL(blob);

        //     // setSrc(url);
        //     setFile({ name: selectedCard.file.name, src: url, type: selectedCard.file.type });
        //     setRenderType("image");
        //   });
        // });
        setFile({
          name: selectedCard.file.name,
          src: `${BOARD_CLOUDFRONT_URL}/${gameId}/${selectedCard.id}/thumbnail.webp?v=${
            selectedCard.version || 0
          }`,
          type: selectedCard.file.type,
        });
        setRenderType("image");
        break;
    }

    // fetch(`${baseURL}/thumbnail?id=${selectedCard.id}&gameId=${gameId}`).then((res) => {
    //   res.blob().then((blob) => {
    //     const url = URL.createObjectURL(blob);

    //     setSrc(url);
    //   });
    // });
  }, [selectedCard]);

  useEffect(() => {
    if (layout.section) {
      const sectionPost = board.list.filter((item) => item.section === selectedCard.section);

      setListCount(sectionPost.length);
      setCurrentIndex(sectionPost.findIndex((item) => item.id === selectedCard.id));
      setPostList(sectionPost);
    } else {
      setListCount(board.list.length);
      setCurrentIndex(board.list.findIndex((item) => item.id === selectedCard.id));
      setPostList(board.list);
    }
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      slotProps={{ backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0)" } } }}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
          height: "100%",
          opacity,
          transition: "opacity .5s",
          position: "relative",
          outline: "none",
          overflow: "auto",
        }}
      >
        <IconButton onClick={onClose} sx={{ position: "absolute", top: 10, left: 10 }}>
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            position: "absolute",
            top: 5,
            left: "50%",
            transform: "translate(-50%, 0%)",
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <IconButton onClick={selectBackCard} disabled={currentIndex === 0}>
            <ArrowBackIosIcon />
          </IconButton>

          <Typography sx={{ font: "600 24px Pretendard" }}>{`${
            currentIndex + 1
          } / ${listCount}`}</Typography>

          <IconButton onClick={selectForwardCard} disabled={currentIndex === listCount - 1}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>

        <IconButton
          sx={{ position: "absolute", top: 10, right: 10 }}
          onClick={(e) => {
            e.stopPropagation();

            setAnchorEl(e.currentTarget);
          }}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(null);
          }}
        >
          <MenuItem onClick={modifyCard} sx={{ px: "20px" }}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <EditIcon />

              <Typography sx={{ fontWeight: "600" }}>수정하기</Typography>
            </Box>
          </MenuItem>

          <MenuItem onClick={deleteCard} sx={{ px: "20px", color: "red" }}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <DeleteIcon />

              <Typography sx={{ fontWeight: "600" }}>삭제하기</Typography>
            </Box>
          </MenuItem>

          {/* {file && !selectedCard.link && selectedCard.file && (
            <MenuItem onClick={downloadFile} sx={{ px: "20px" }}>
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <DownloadIcon />

                <Typography sx={{ fontWeight: "600" }}>파일 다운로드</Typography>
              </Box>
            </MenuItem>
          )} */}
        </Menu>

        <Box
          sx={{
            height: "100%",
            pt: "55px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ font: "700 28px Pretendard", wordBreak: "break-all", pl: "10px" }}>
            {selectedCard.title}
          </Typography>

          {selectedCard.file && !selectedCard.link && (
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                color: "#777",
                bgcolor: "#ddd",
                p: "2.5px 5px",
              }}
            >
              <AttachFileIcon sx={{ color: "#777" }} />

              <Typography noWrap sx={{ wordBreak: "break-all", pr: "10px" }}>
                첨부파일 : {selectedCard.file.name}
              </Typography>
            </Box>
          )}

          {file && renderType === "iframe" && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                "& iframe": {
                  position: "absolute",
                  opacity: iframeOpacity,
                  width: "calc(100% - 20px)",
                  height: "calc(100% - 20px)",
                  zIndex: 2,
                },

                "& > div": {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                },
              }}
            >
              <iframe
                src={file.src}
                onLoad={() => {
                  setIframeOpacity(1);
                }}
                onError={() => {
                  console.log("error");
                }}
              />

              <Box>
                <CircularProgress />
              </Box>
            </Box>
          )}

          {file && renderType === "pdf" && (
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "calc(100% - 20px)",
                  height: "calc(100% - 20px)",
                  overflow: "auto",
                  zIndex: 1,
                  border: "2px solid #333",
                  borderRadius: "8px",
                }}
              >
                <PdfViewer pdfUrl={file.src} />
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 0,
                }}
              >
                <CircularProgress />
              </Box>
            </Box>
          )}

          {file && renderType === "image" && (
            <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
              <img
                src={file.src}
                alt={file.name}
                style={{ height: "100%", objectFit: "contain", position: "absolute" }}
                onClick={handleImageModal}
              />
            </Box>
          )}

          {selectedCard.link && (
            <Box sx={{ display: "flex", justifyContent: "center", mb: "5px" }}>
              <Button
                variant="contained"
                sx={{ display: "flex", gap: "10px", font: "700 18px Pretendard" }}
                onClick={() => window.open(selectedCard.link)}
              >
                <OpenInNewIcon /> 링크로 이동하기
              </Button>
            </Box>
          )}

          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              px: "10px",
            }}
          >
            <Typography
              sx={{
                wordBreak: "break-all",
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
                pr: "10px",
                font: "500 14px Pretendard",
              }}
              onClick={() => window.open(selectedCard.link)}
            >
              {selectedCard.link}
            </Typography>

            <Typography
              sx={{ font: "400 18px Pretendard", whiteSpace: "pre-wrap", wordBreak: "break-all" }}
            >
              {selectedCard.explain}
            </Typography>

            <Box sx={{ border: "1px solid #eee" }} />

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box sx={{ display: "flex", gap: "5px" }} onClick={handleLikes}>
                {selectedCard.likes?.includes(uuid) ? (
                  <FavoriteIcon sx={{ color: "#c33c3c" }} />
                ) : (
                  <FavoriteBorderIcon sx={{ color: "#555" }} />
                )}
                <Typography sx={{ font: "600 20px Pretendard" }}>
                  {selectedCard.likes?.length || 0}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", gap: "5px", alignItems: "center", pr: "5px" }}>
                <ChatBubbleOutlineIcon fontSize="large" sx={{ scale: "0.8", color: "#555" }} />
                <Typography sx={{ font: "600 20px Pretendard" }}>
                  {selectedCard.comments?.length || 0}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: "100%",
                maxHeight: "30vh",
              }}
            >
              {replyId && (
                <Box
                  sx={{
                    position: "relative",
                    bgcolor: "#f5f5f5",
                    borderRadius: "10px",
                    p: "10px",
                    border: "2px solid #c33c3c",
                    opacity: "0.7",
                    // display: "flex",
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => setReplyId(null)}
                    sx={{ position: "absolute", top: 0, right: 0, bgcolor: "#aaa" }}
                  >
                    <CloseIcon sx={{ fontSize: "20px" }} />
                  </IconButton>
                  {(() => {
                    const comment = selectedCard.comments?.find(
                      (item) => item.date + "" === replyId + ""
                    );

                    return (
                      <>
                        <Typography sx={{ font: "600 16px Pretendard", mb: "5px" }}>
                          {comment?.user}
                        </Typography>

                        <Typography noWrap sx={{ font: "500 14px Pretendard", width: "100%" }}>
                          {comment?.comment}
                        </Typography>

                        <Typography sx={{ textAlign: "right" }}>에 대한 답글</Typography>
                      </>
                    );
                  })()}
                </Box>
              )}

              <Box sx={{ display: "flex", gap: "10px" }}>
                <Textfield
                  size="small"
                  sx={{ width: "200px", height: "30px" }}
                  disabled={isAnonymous}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ width: "95px" }}>
                        <Typography sx={{ font: "600 16px Pretendard", pt: "5px" }}>
                          이름 :
                        </Typography>
                      </Box>
                    ),
                  }}
                  placeholder="익명"
                />

                <FormControlLabel
                  label="익명"
                  control={
                    <Checkbox
                      checked={isAnonymous}
                      onChange={() => setIsAnonymous((prev) => !prev)}
                    />
                  }
                />
              </Box>
              <Textfield
                fullWidth
                size="small"
                placeholder="댓글 추가"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <Button sx={{ fontWeight: "600" }} onClick={addComment} variant="contained">
                      등록
                    </Button>
                  ),
                  style: { paddingRight: "0" },
                  ref: ref,
                }}
              />

              <Box>
                {selectedCard.comments?.map((item, index) => (
                  <Box
                    sx={{
                      bgcolor: index % 2 === 0 ? "#eee" : "#f5f5f5",
                      borderRadius: "10px",
                      p: "10px",
                      mb: "10px",
                    }}
                    key={index}
                  >
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Typography
                        sx={{
                          font: "600 18px Pretendard",
                          mb: "10px",
                          wordBreak: "break-all",
                          flex: 1,
                        }}
                      >
                        {item.user}
                      </Typography>

                      <Typography sx={{ font: "500 14px Pretendard", color: "#777", pt: "6px" }}>
                        {(() => {
                          const date = new Date(item.date);
                          const now = new Date();

                          const diff = now - date;

                          if (diff < 1000 * 60) {
                            return "방금 전";
                          }

                          if (diff < 1000 * 60 * 60) {
                            return `${Math.floor(diff / 1000 / 60)}분 전`;
                          }

                          if (diff < 1000 * 60 * 60 * 24) {
                            return `${Math.floor(diff / 1000 / 60 / 60)}시간 전`;
                          }

                          if (diff < 1000 * 60 * 60 * 24 * 7) {
                            return `${Math.floor(diff / 1000 / 60 / 60 / 24)}일 전`;
                          }

                          if (diff < 1000 * 60 * 60 * 24 * 30) {
                            return `${Math.floor(diff / 1000 / 60 / 60 / 24 / 7)}주 전`;
                          }

                          if (diff < 1000 * 60 * 60 * 24 * 365) {
                            return `${Math.floor(diff / 1000 / 60 / 60 / 24 / 30)}개월 전`;
                          }

                          return `${date.getFullYear()}년 ${
                            date.getMonth() + 1
                          }월 ${date.getDate()}일`;
                        })()}
                      </Typography>
                    </Box>

                    <Typography
                      sx={{
                        font: "400 16px Pretendard",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-all",
                      }}
                    >
                      {item.comment}
                    </Typography>

                    <Button
                      sx={{
                        p: 0,
                        color: "#888",
                        fontWeight: "600",
                        pt: "5px",
                        display: "block",
                        minWidth: "0px",
                      }}
                      onClick={handleReply}
                      id={item.date}
                    >
                      답글 달기
                    </Button>

                    {item.replies && (
                      <Box>
                        <Divider sx={{ mt: "10px", borderColor: "#ddd" }} />

                        {item.replies.map((reply, index) => (
                          <Box
                            sx={{
                              borderRadius: "10px",
                              pt: "5px",
                              pl: "10px",
                              mb: "5px",
                            }}
                            key={index}
                          >
                            <Box sx={{ display: "flex", gap: "10px" }}>
                              <Typography sx={{ font: "600 16px Pretendard", mb: "10px", flex: 1 }}>
                                {reply.user}
                              </Typography>

                              <Typography
                                sx={{
                                  font: "500 12px Pretendard",
                                  color: "#777",
                                  pt: "6px",
                                }}
                              >
                                {(() => {
                                  const date = new Date(reply.date);
                                  const now = new Date();

                                  const diff = now - date;

                                  if (diff < 1000 * 60) {
                                    return "방금 전";
                                  }

                                  if (diff < 1000 * 60 * 60) {
                                    return `${Math.floor(diff / 1000 / 60)}분 전`;
                                  }

                                  if (diff < 1000 * 60 * 60 * 24) {
                                    return `${Math.floor(diff / 1000 / 60 / 60)}시간 전`;
                                  }

                                  if (diff < 1000 * 60 * 60 * 24 * 7) {
                                    return `${Math.floor(diff / 1000 / 60 / 60 / 24)}일 전`;
                                  }

                                  if (diff < 1000 * 60 * 60 * 24 * 30) {
                                    return `${Math.floor(diff / 1000 / 60 / 60 / 24 / 7)}주 전`;
                                  }

                                  if (diff < 1000 * 60 * 60 * 24 * 365) {
                                    return `${Math.floor(diff / 1000 / 60 / 60 / 24 / 30)}개월 전`;
                                  }

                                  return `${date.getFullYear()}년 ${
                                    date.getMonth() + 1
                                  }월 ${date.getDate()}일`;
                                })()}
                              </Typography>
                            </Box>

                            <Typography
                              sx={{
                                font: "400 14px Pretendard",
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-all",
                              }}
                            >
                              {reply.comment}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>

        {isImageModalOpened && <ImageModal open={isImageModalOpened} onClose={handleImageModal} />}
        {isDeleteModalOpened && (
          <DeleteModal
            open={isDeleteModalOpened}
            onClose={handleDeleteModal}
            targetId={selectedCard.id}
          />
        )}
      </Box>
    </Modal>
  );
};

const ImageModal = (props) => {
  const { gameId } = useParams();
  const { open, onClose } = props;

  const { selectedCard } = useContext(UserContext);

  const [src, setSrc] = useState(null);

  // useEffect(() => {
  //   if (!selectedCard || !selectedCard.file) return;

  //   fetch(`${baseURL}/thumbnail?id=${selectedCard.id}&gameId=${gameId}`).then((res) => {
  //     res.blob().then((blob) => {
  //       const url = URL.createObjectURL(blob);

  //       setSrc(url);
  //     });
  //   });
  // }, [selectedCard]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ p: "10px" }}
      slotProps={{ backdrop: { style: { backgroundColor: "#222" } } }}
    >
      <Box sx={{ width: "100%", height: "100%", outline: "none" }}>
        <img
          src={`${BOARD_CLOUDFRONT_URL}/${gameId}/${selectedCard.id}/thumbnail.webp?v=${
            selectedCard.version || 0
          }`}
          style={{ objectFit: "contain", maxHeight: "100%" }}
          onClick={onClose}
          image-id={selectedCard.id}
        />
      </Box>
    </Modal>
  );
};

export const DeleteModal = (props) => {
  const { open, onClose, targetId } = props;
  const { gameId } = useParams();
  const { socket } = useContext(UserContext);
  const SnackBar = useContext(SnackBarContext);

  const [loading, setLoading] = useState(false);

  const deletePost = (e) => {
    e.stopPropagation();
    setLoading(true);

    socket.emit("deletePost", { gameId, postId: targetId }, (res) => {
      if (res.success) {
        onClose();

        SnackBar.setOpen(true);
        SnackBar.setSeverity("success");
        SnackBar.setText("게시물이 삭제되었습니다.");
      }
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      disablePortal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          bgcolor: "#fff",
          borderRadius: "10px",
          p: "30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ font: "700 28px Pretendard", mb: "20px" }}>
          정말 삭제하시겠습니까?
        </Typography>

        <Typography sx={{ font: "400 18px Pretendard", mb: "30px" }}>
          삭제된 게시물은 복구할 수 없습니다.
        </Typography>

        <Box sx={{ display: "flex", gap: "10px" }}>
          <LoadingButton
            sx={{
              bgcolor: "#aaa",
              color: "#fff",
              fontWeight: "700",
              "&:hover": { bgcolor: "#999" },
            }}
            onClick={onClose}
            loading={loading}
          >
            취소
          </LoadingButton>

          <LoadingButton
            sx={{
              bgcolor: "#f00",
              color: "#fff",
              fontWeight: "700",
              "&:hover": { bgcolor: "#e00" },
            }}
            onClick={deletePost}
            loading={loading}
          >
            삭제
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};
