import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { GET_USER_RESULTS, GET_USER_PDF } from "utils/api/Hexagram";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ManagerContext } from "views/LiveHexagram/contexts/ManagerContext";
import { useContext } from "react";
import { Box } from "@mui/material";
import { Textfield } from "./Textfield";
import SearchIcon from "@mui/icons-material/Search";

const columns = [
  { id: "date", label: "검사일", minWidth: 110, align: "center" },
  { id: "affiliation", label: "소속", minWidth: 110, align: "center" },
  { id: "name", label: "이름", minWidth: 110, align: "center" },
  { id: "pl", label: "유희형", minWidth: 70, align: "center" },
  { id: "pe", label: "신념형", minWidth: 70, align: "center" },
  { id: "dr", label: "상상형", minWidth: 70, align: "center" },
  { id: "an", label: "분석형", minWidth: 70, align: "center" },
  { id: "ac", label: "행동형", minWidth: 70, align: "center" },
  { id: "em", label: "감성형", minWidth: 70, align: "center" },
  { id: "download", label: "결과 다운로드", minWidth: 100, align: "center" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderRight: "1px solid rgba(224, 224, 224, 1)",
  "&:last-child": {
    borderRight: 0,
  },
}));

export default function StickyHeadTable() {
  const { gameId } = useParams();
  const { socket } = useContext(ManagerContext);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [sortedRows, setSortedRows] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    if (!socket) return;
    socket.on("getUserResults", (data) => {
      const userResult = data.users;
      const finishedUsers = userResult.filter((user) => user.result);

      const fetchedRows = finishedUsers.map((user) => ({
        date: user.date,
        affiliation: user.affiliation,
        name: user.name,
        pl: user.result[0],
        pe: user.result[1],
        dr: user.result[2],
        an: user.result[3],
        ac: user.result[4],
        em: user.result[5],
        userId: user.userId,
      }));
      setSortedRows(fetchedRows);
    });

    return () => {
      socket.off("getUserResults");
    };
  });

  useEffect(() => {
    GET_USER_RESULTS(gameId)
      .then((res) => {
        const userResult = res.data.users;
        const finishedUsers = userResult.filter((user) => user.result);

        const fetchedRows = finishedUsers.map((user) => {
          return {
            date: user.date,
            affiliation: user.affiliation,
            name: user.name,
            pl: user.result[0],
            pe: user.result[1],
            dr: user.result[2],
            an: user.result[3],
            ac: user.result[4],
            em: user.result[5],
            userId: user.userId,
          };
        });
        setSortedRows(fetchedRows);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [gameId]);

  const handleSortDate = () => {
    const sorted = [...sortedRows].sort((a, b) => {
      if (order === "asc") {
        return new Date(b.date) - new Date(a.date);
      }
      return new Date(a.date) - new Date(b.date);
    });
    setSortedRows(sorted);
    setOrder(order === "asc" ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getMaxMinIndexes = (row) => {
    const values = [row.pl, row.pe, row.dr, row.an, row.ac, row.em];
    const maxIndex = values.indexOf(Math.max(...values));
    values[maxIndex] = -Infinity; // Max 값은 제외하고 두 번째 Max 값 찾기
    const secondMaxIndex = values.indexOf(Math.max(...values));
    return { maxIndex, secondMaxIndex };
  };

  const handleDownload = (userId) => {
    console.log(userId);
    window.open(
      `https://www.withplus.live/hexagram/user/${gameId}/user-result/${userId}/`,
      "_blank"
    );
  };

  const handleSearchInput = (e) => {
    if (e.target.value === "") {
      setFilteredRows([]);
    }
    setSearchInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const filteredRows = sortedRows.filter((row) => row.name.includes(searchInput));

      setFilteredRows(filteredRows);
    }
  };

  useEffect(() => {
    if (searchInput === "") {
      setFilteredRows(sortedRows);
    }
  }, [searchInput, sortedRows]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "10px", mt: "-15px" }}>
        <Textfield
          placeholder="이름을 입력 후 엔터키를 눌러주세요."
          sx={{ width: "500px" }}
          InputProps={{
            startAdornment: <SearchIcon sx={{ mr: "10px" }} />,
          }}
          value={searchInput}
          onChange={handleSearchInput}
          onKeyPress={handleKeyPress}
        />
      </Box>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      font: "600 16px pretendard",
                      cursor: column.id === "date" ? "pointer" : "default",
                    }}
                    onClick={column.id === "date" ? handleSortDate : undefined}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {sortedRows.length > 0 ? ( */}
              {filteredRows.length > 0 ? (
                // sortedRows
                filteredRows
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return <Row {...row} key={row.userId} />;
                  })
              ) : (
                //   const { maxIndex, secondMaxIndex } = getMaxMinIndexes(row);
                //   return (
                //     <TableRow hover role="checkbox" tabIndex={-1} key={row.userId}>
                //       {columns.map((column, columnIndex) => {
                //         const value = row[column.id];
                //         const style = { font: "600 15px pretendard" }; // 기본 글꼴 크기 설정
                //         if (columnIndex === maxIndex + 3) {
                //           style.backgroundColor = "#FF968A";
                //           style.font = "700 19px pretendard";
                //         } else if (columnIndex === secondMaxIndex + 3) {
                //           style.backgroundColor = "#55CBCD";
                //           style.font = "600 17px pretendard";
                //         }
                //         return (
                //           <StyledTableCell key={column.id} align={column.align} style={style}>
                //             {column.id === "download" ? (
                //               <Button
                //                 variant="contained"
                //                 color="primary"
                //                 onClick={() => handleDownload(row.userId)}
                //               >
                //                 download
                //               </Button>
                //             ) : column.format && typeof value === "number" ? (
                //               column.format(value)
                //             ) : (
                //               value
                //             )}
                //           </StyledTableCell>
                //         );
                //       })}
                //     </TableRow>
                //   );
                // })
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    결과 값이 아직 없습니다
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          // rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={sortedRows.length}
          // rowsPerPage={rowsPerPage}
          rowsPerPage={sortedRows.length}
          page={page}
          // onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              display: "none",
            },

            "& .MuiInputBase-root": {
              display: "none",
            },
          }}
        >
          hello
        </TablePagination>
      </Paper>
    </>
  );
}

const Row = React.memo((row) => {
  const { gameId } = useParams();

  const getMaxMinIndexes = (row) => {
    const values = [row.pl, row.pe, row.dr, row.an, row.ac, row.em];
    const maxIndex = values.indexOf(Math.max(...values));
    values[maxIndex] = -Infinity; // Max 값은 제외하고 두 번째 Max 값 찾기
    const secondMaxIndex = values.indexOf(Math.max(...values));
    return { maxIndex, secondMaxIndex };
  };

  const handleDownload = (userId) => {
    console.log(userId);
    window.open(
      `https://www.withplus.live/hexagram/user/${gameId}/user-result/${userId}/`,
      "_blank"
    );
  };

  const { maxIndex, secondMaxIndex } = getMaxMinIndexes(row);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.userId}>
      {columns.map((column, columnIndex) => {
        const value = row[column.id];
        const style = { font: "600 15px pretendard" }; // 기본 글꼴 크기 설정
        if (columnIndex === maxIndex + 3) {
          style.backgroundColor = "#FF968A";
          style.font = "700 19px pretendard";
        } else if (columnIndex === secondMaxIndex + 3) {
          style.backgroundColor = "#55CBCD";
          style.font = "600 17px pretendard";
        }
        return (
          <StyledTableCell key={column.id} align={column.align} style={style}>
            {column.id === "download" ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleDownload(row.userId)}
              >
                download
              </Button>
            ) : column.format && typeof value === "number" ? (
              column.format(value)
            ) : (
              value
            )}
          </StyledTableCell>
        );
      })}
    </TableRow>
  );
});
