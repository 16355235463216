import Title from "components/Title";
import * as S from "./styles";
import * as API from "utils/api/Tap";
import { ListPageUserInfo as UserInfo } from "components/UserInfo";
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { CustomButton as Button } from "components/Button";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";

import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ReplayIcon from "@mui/icons-material/Replay";

import { SnackBar } from "components/SnackBar";
import { AddTapModal, EditTapModal } from "components/Modal/Modal";
import { useCookies } from "react-cookie";
import { DeleteLiveTapModal, ResetTapModal } from "components/Modal";

const TapListPage = () => {
  const navigate = useNavigate();
  const [checkedList, setCheckedList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [lists, setLists] = useState([]);
  const [isAddModalOpened, setAddModalOpened] = useState(false);

  const [snackBarOpened, setSnackBarOpened] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [snackBarText, setSnackBarText] = useState("");

  const [selectedGame, setSelectedGame] = useState(null);

  const [deleteLiveTapModalOpened, setDeleteLiveTapModalOpened] = useState(false);

  const [editTapModalOpened, setEditTapModalOpened] = useState(false);
  const [resetModalOpened, setResetModalOpened] = useState(false);
  const [modalId, setModalId] = useState("");

  const [cookies] = useCookies();

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCheckList = (list) => {
    setCheckedList([...list]);
  };

  const handleAddTapModal = () => {
    setAddModalOpened(!isAddModalOpened);
  };
  const handleSnackBar = () => {
    setSnackBarOpened(false);
  };

  const handleEditTapModal = (e) => {
    if (e) {
      setModalId(e.currentTarget.id);
    }
    setEditTapModalOpened(!editTapModalOpened);

    setAnchorEl(null);
  };

  const getTapList = () => {
    API.GET_LIST_TAP(cookies.auth).then((res) => {
      const { success, lists, message } = res.data;
      if (success) {
        let newLists = [];
        for (let list of lists) {
          newLists.push({
            id: list._id,
            name: list.name,
            type: "Live Tap",
            gameMode: list.gameMode === "team" ? `단체전 (${list.teamCount}팀)` : "개인전",
            created: list.created.slice(0, 10),
          });
        }
        setLists(newLists);
      } else {
        switch (message) {
          case "Login Expired":
            alert("로그인이 만료되었습니다.");
            // navigate("/login", { replace: true });
            window.location.replace("/login");
            break;
          default:
            alert("오류가 발생했습니다. 다시 시도해주세요.");
            break;
        }
      }
    });
  };

  const handleDeleteLiveTapModal = (e) => {
    setModalId(e.currentTarget.id);
    setDeleteLiveTapModalOpened(!deleteLiveTapModalOpened);
    setAnchorEl(null);
  };

  const handleResetTapModal = (e) => {
    setAnchorEl(null);

    setModalId(e.currentTarget.id);
    setResetModalOpened(true);
  };

  const resetTap = () => {
    API.RESET_GAME(modalId).then((res) => {
      let { success, message } = res.data;

      const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      sleep(750).then(() => {
        if (success) {
          setSnackBarOpened(true);
          setSnackBarSeverity("success");
          setSnackBarText("리셋이 완료되었습니다.");
        } else {
          setSnackBarOpened(true);
          setSnackBarSeverity("error");
          setSnackBarText("리셋에 실패하였습니다. 다시 시도해주세요." + message);
        }

        setResetModalOpened(false);
      });
    });
  };

  const deleteLiveTap = (id) => {
    API.DELETE_TAP(id ? [id] : checkedList, (res) => {
      let data = res.data;

      setTimeout(() => {
        setSnackBarOpened(true);
        if (data.success) {
          setSnackBarSeverity("success");
          setSnackBarText("삭제가 완료되었습니다.");
          getTapList();
        } else {
          setSnackBarSeverity("error");
          setSnackBarText("삭제에 실패하였습니다. 다시 시도해주세요.");
        }
        setDeleteLiveTapModalOpened(false);
      }, 500);
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "프로젝트 이름",
      flex: 2,
      renderCell: (params) => {
        if (!params.value)
          return (
            <Link to={`/tap/edit/${params.id}`} style={{ textDecoration: "none", width: "100%" }}>
              <Typography color="error" sx={{ fontFamily: "Pretendard", fontWeight: "600" }}>
                수정이 필요한 탭입니다. 여기를 클릭하여 수정을 완료해주세요.
              </Typography>
            </Link>
          );
        return (
          <Tooltip title="진행 페이지 이동" arrow>
            <Link
              to={`/tap/manager/${params.id}`}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <Box id={params.id} sx={{ cursor: "pointer", width: "100%", display: "flex" }}>
                <PlayCircleFilledWhiteIcon
                  sx={{ mr: "10px", color: "#9a9a9a", "&:hover": { color: "#ababab" } }}
                />
                <Typography sx={{ fontFamily: "Pretendard", fontWeight: "600", color: "#222" }}>
                  {params.value}
                </Typography>
              </Box>
            </Link>
          </Tooltip>
        );
      },
    },
    { field: "gameMode", headerName: "게임 타입", flex: 1 },
    { field: "type", headerName: "타입", flex: 1 },
    { field: "created", headerName: "날짜", flex: 1 },
    {
      field: "action",
      headerName: "",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <>
            <IconButton id={params.id} onClick={handleMenu}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={params.id}
              anchorEl={anchorEl}
              open={anchorEl === null ? false : params.id === anchorEl.id}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  width: "200px",
                  boxShadow: "0px 3px 6px #00000020",
                  padding: "0 10px",
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem onClick={handleEditTapModal} sx={{ mb: "5px" }} id={params.id}>
                <EditIcon sx={{ mr: "10px" }} />
                <Typography sx={{ font: "600 1rem Pretendard" }}>수정</Typography>
              </MenuItem>

              <MenuItem onClick={handleResetTapModal} sx={{ mb: "5px" }} id={params.id}>
                <ReplayIcon sx={{ mr: "10px" }} />
                <Typography sx={{ font: "600 1rem Pretendard" }}>리셋</Typography>
              </MenuItem>

              <MenuItem
                onClick={handleDeleteLiveTapModal}
                sx={{ mb: "5px", color: "#e00" }}
                id={params.id}
              >
                <DeleteForeverOutlinedIcon sx={{ mr: "10px" }} />
                <Typography sx={{ font: "600 1rem Pretendard" }}>삭제</Typography>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    getTapList();
  }, []);

  return (
    <S.Body>
      <S.Content>
        <Title children={{ name: "Live Tap" }} />
        <UserInfo />
        <S.MyListBox>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ font: "bold 25px Pretendard" }}>My List</Typography>
            <Button
              sx={{ font: "600 18px Pretendard", py: "10px" }}
              onClick={checkedList.length > 0 ? handleDeleteLiveTapModal : handleAddTapModal}
            >
              {checkedList.length > 0 ? "삭제" : "추가"}
            </Button>
          </Box>
          <S.MyList>
            <DataGrid
              columns={columns}
              rows={lists}
              rowsPerPageOptions={[100]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={handleCheckList}
              selectionModel={checkedList}
              disableColumnMenu
              sx={{
                font: "600 16px Pretendard",
                bgcolor: "#fff",
                borderRadius: "15px",
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "created", sort: "desc" }],
                },
              }}
            />
          </S.MyList>
        </S.MyListBox>
      </S.Content>
      <SnackBar
        open={snackBarOpened}
        onClose={handleSnackBar}
        severity={snackBarSeverity}
        text={snackBarText}
      />
      <AddTapModal
        open={isAddModalOpened}
        onClose={handleAddTapModal}
        selectedGame={selectedGame}
        getList={getTapList}
      />
      <EditTapModal
        open={editTapModalOpened}
        onClose={handleEditTapModal}
        getList={getTapList}
        id={modalId}
      />
      {deleteLiveTapModalOpened && (
        <DeleteLiveTapModal
          open={deleteLiveTapModalOpened}
          handleModal={handleDeleteLiveTapModal}
          deleteLiveTap={deleteLiveTap}
          count={checkedList.length}
          id={modalId}
        />
      )}
      {resetModalOpened && (
        <ResetTapModal open={resetModalOpened} onClose={setResetModalOpened} resetTap={resetTap} />
      )}
    </S.Body>
  );
};

export default TapListPage;
