import React, { createContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
import {
  InputNameContainer,
  MidResultContainer,
  QuizContainer,
  QuizSlideContainer,
  WaitingSlide,
  QuizResultContainer,
  Outro,
} from "../QuizUserPage";
import { LoadingModal } from "components/Modal";

const QuizUserContext = createContext({});

const QuizUserProvider = ({ children }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isSocketDisconnected, setSocketDisconnected] = useState(false);

  const [name, setName] = useState("");
  const [imgIdx, setImgIdx] = useState(null);
  const [score, setScore] = useState(0);
  const [isStarted, setStarted] = useState(false);
  const [isTimeOver, setTimeOver] = useState(false);
  const [slideList, setSlideList] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);
  const [isAnswerOpened, setAnswerOpened] = useState(false);
  const [isResultOpened, setResultOpened] = useState(false);
  const [timer, setTimer] = useState(10);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [currentScore, setCurrentScore] = useState(0);
  const [isSubmitted, setSubmitted] = useState(false);
  const [rank, setRank] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(<InputNameContainer />);
  const [currentContainer, setCurrentContainer] = useState(<QuizSlideContainer timer={timer} />);
  const [playingUsers, setPlayingUsers] = useState([]);
  const [beforeRank, setBeforeRank] = useState(sessionStorage.getItem("beforeRank") * 1 || 1);
  const [isAllQuizOver, setIsAllQuizOver] = useState(false);

  const setPlayingData = (data) => {
    setStarted(data.isStarted);
    setTimeOver(data.isTimeOver);
    setSlideList(data.slideList);
    setSlideIndex(data.slideIndex);
    setAnswerOpened(data.isAnswerOpened);
    setResultOpened(data.isResultOpened);
  };

  const handleName = (name) => {
    setName(name);
  };

  useEffect(() => {
    if (!socket) {
      setSocket(io.connect("https://quiz.withplus.live", { cors: { origin: "*" } }));
    }
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", () => {
      console.log("connected");
      setSocketDisconnected(false);

      socket.emit("joinRoom", { type: "Live Quiz", id: id, uid: cookies.uid }, (res) => {
        console.log(res);
      });
    });

    socket.on("disconnect", () => {
      setSocketDisconnected(true);
      console.log("disconnected");
    });

    socket.on("enterUser", (data) => {
      if (data.success === false) {
        alert(data.message);

        // navigate("/");
        window.location.replace("/");

        return;
      }

      socket.emit("joinRoom", { type: "Live Quiz", id: id, uid: data.uid });
      setName(data.name);
      setImgIdx(data.imgIdx);
      setPlayingData(data.quiz.playingData);

      setTimeout(() => {
        if (data.isQuizStarted) {
          // * 현재 진행중인 퀴즈로 이동
          setCurrentSlide(<QuizContainer />);
        } else {
          setCurrentSlide(<WaitingSlide />);
        }
      }, 700);
    });

    socket.on("resignUser", () => {
      alert(" 진행자가 귀하를 퀴즈방에서 제외시켰습니다. \n 초기화면으로 돌아갑니다.");

      window.location.replace("/");
    });

    socket.on("startQuiz", (data) => {
      setPlayingData(data.playingData);
      setTimer(data.playingData.slideList[0].timer);

      if (name === "" || name === null) return;
      setCurrentSlide(<QuizContainer />);
    });

    socket.on("quizStart", (data) => {
      const playingData = data.quiz.playingData;

      setStarted(playingData.isStarted);
    });

    socket.on("showQuestionAnswer", (data) => {
      setAnswerOpened(true);
    });

    socket.on("showQuestionResult", (data) => {
      let user = data.quiz.playingUsers.filter((user) => user.uid + "" === cookies.uid)[0];
      setScore(user.score);
      setCurrentContainer(<MidResultContainer />);
      setPlayingUsers(data.quiz.playingUsers);
      const myCurrentScore = data.quiz.playingData.submittedAnswers.filter(
        (answer) => answer.uid === cookies.uid
      )[0]?.score;

      if (myCurrentScore) {
        setCurrentScore(myCurrentScore);
      } else {
        setCurrentScore(0);
      }

      // setCurrentScore(
      //   data.quiz.playingData.submittedAnswers.filter((answer) => answer.uid === cookies.uid)[0]
      //     .score
      // );
    });

    socket.on("nextQuiz", (data) => {
      const quiz = data.quiz;

      setPlayingData(quiz.playingData);
      setTimer(quiz.playingData.slideList[quiz.playingData.slideIndex].timer);
      setCurrentContainer(<QuizSlideContainer timer={timer} />);
      setSubmitted(false);
      setSelectedAnswers([]);
    });

    socket.on("quizResult", (data) => {
      const quiz = data.quiz;
      let users = quiz.playingUsers;

      users.sort((a, b) => {
        return b.score - a.score;
      });

      var index = users.findIndex((user) => user.uid + "" === cookies.uid);
      setRank(index + 1);
      setIsAllQuizOver(true);
      setCurrentContainer(<QuizResultContainer />);
    });

    socket.on("resetGame", () => {
      alert("해당 퀴즈가 종료되었습니다.");

      setTimeout(() => {
        window.location.href = "/";
      }, 300);
    });

    socket.on("timer", (data) => {
      setTimer(data.timer);
    });

    socket.on("goToOutro", () => {
      console.log("goToOutro");
      setCurrentSlide(<Outro />);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("enterUser");
      socket.off("resignUser");
      socket.off("startQuiz");
      socket.off("quizStart");
      socket.off("showQuestionAnswer");
      socket.off("showQuestionResult");
      socket.off("nextQuiz");
      socket.off("quizResult");
      socket.off("resetGame");
      socket.off("timer");
      socket.off("goToOutro");
    };
  });

  const value = {
    name,
    isStarted,
    setStarted,
    setTimeOver,
    slideList,
    slideIndex,
    setPlayingData,
    setResultOpened,
    isAnswerOpened,
    setAnswerOpened,
    score,
    setScore,
    timer,
    setTimer,
    selectedAnswers,
    setSelectedAnswers,
    currentScore,
    setCurrentScore,
    isSubmitted,
    setSubmitted,
    rank,
    setRank,
    currentSlide,
    setCurrentSlide,
    socket,
    currentContainer,
    setCurrentContainer,
    handleName,
    imgIdx,
    setImgIdx,
    beforeRank,
    setBeforeRank,
    playingUsers,
    setPlayingUsers,
    isAllQuizOver,
    setIsAllQuizOver,
  };
  return (
    <QuizUserContext.Provider value={value}>
      <>
        {children}
        <LoadingModal open={isSocketDisconnected} />
      </>
    </QuizUserContext.Provider>
  );
};

export { QuizUserContext, QuizUserProvider };
