import * as S from "./styles";
import * as API from "utils/api/Hexagram";

import Title from "components/Title";
import { ListPageUserInfo as UserInfo } from "components/UserInfo";
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { CustomButton as Button } from "components/Button";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { SnackBar } from "components/SnackBar";
import { DeleteHexagramModal } from "components/Modal";

import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import AddIcon from "@mui/icons-material/Add";
import UndoIcon from "@mui/icons-material/Undo";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { AddHexagramModal, AddTokensModal } from "components/Modal/Modal"; // AddTokensModal 임포트 추가

const HexagramListPage = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [polls, setPolls] = useState([]);
  const [lists, setLists] = useState([]);
  const [snackBarOpened, setSnackBarOpened] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [snackBarText, setSnackBarText] = useState("");

  const [modalId, setModalId] = useState("");
  const [deleteHexagramModalOpened, setDeleteHexagramModalOpened] = useState(false);

  const [cookies] = useCookies();

  const [checkedList, setCheckedList] = useState([]);

  const [isAddModalOpened, setAddModalOpened] = useState(false);
  const [isAddTokensModalOpened, setAddTokensModalOpened] = useState(false); // 추가된 상태

  const [selectedGame, setSelectedGame] = useState(null);

  const [hexagramPoint, setHexagramPoint] = useState(0);
  const [currentHexagramId, setCurrentHexagramId] = useState(null); // 추가된 상태

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCheckList = (list) => {
    setCheckedList([...list]);
  };

  const handleSnackBar = () => {
    setSnackBarOpened(false);
  };

  // Hexagram 모달 나오게 하는 함수
  const handleAddHexagramModal = () => {
    setAddModalOpened(!isAddModalOpened);
  };

  // 추가된 함수
  const handleAddTokensModal = (hexagramId) => {
    setCurrentHexagramId(hexagramId);
    setAddTokensModalOpened(!isAddTokensModalOpened);
  };

  // 서버에서 hexagram API 가져오는 함수
  const getHexagramList = async () => {
    try {
      const res = await API.GET_LIST_HEXAGRAM(cookies.auth);
      const { success, lists, message } = res.data;
      if (success) {
        let newLists = [];
        for (let list of lists) {
          let tokenCount = 0;
          try {
            const tokenRes = await API.CHECK_HEXAGRAM_TOKENS(cookies.auth, list._id);
            if (tokenRes.data.success) {
              tokenCount = tokenRes.data.tokens;
              console.log("tokenCount", tokenCount);
            }
          } catch (error) {
            console.error(`Error fetching tokens for hexagram ${list._id}:`, error);
          }

          newLists.push({
            id: list._id,
            name: list.name,
            type: "Live Hexagram",
            tokenCount: tokenCount,
            created: list.created.slice(0, 10),
          });
        }
        setLists(newLists);
        setHexagramPoint(res.data.hexagramPoint);
      } else {
        switch (message) {
          case "Login Expired":
            alert("로그인이 만료되었습니다.");
            // navigate("/login", { replace: true });
            window.location.replace("/login");
            break;
          default:
            alert("알 수 없는 오류가 발생했습니다.");
            break;
        }
      }
    } catch (error) {
      console.error("Error fetching hexagram list:", error);
      alert("알 수 없는 오류가 발생했습니다.");
    }
  };

  // 리스트에서 hexagram 삭제할거냐고 물어보는 modal 버튼
  const handleDeleteHexagramModal = (e) => {
    setModalId(e.currentTarget.id);
    setDeleteHexagramModalOpened(!deleteHexagramModalOpened);
  };

  // 리스트에서 hexagram 삭제하는 함수
  const deleteHexagram = (id) => {
    const hexagramIds = id ? [id] : checkedList;

    API.DELETE_HEXAGRAM(cookies.auth, hexagramIds).then((res) => {
      const data = res.data;

      setTimeout(() => {
        setSnackBarOpened(true);
        if (data.success) {
          setSnackBarSeverity("success");
          setSnackBarText("삭제가 완료되었습니다.");
          getHexagramList();
        } else {
          setSnackBarSeverity("error");
          setSnackBarText("삭제에 실패하였습니다. 다시 시도해주세요.");
        }
        setDeleteHexagramModalOpened(false);
      }, 500);
    });
  };

  // 리스트에서 hexagram 토큰 회수하는 함수
  const reclaimTokens = (id) => {
    API.RECLAIM_TOKENS(cookies.auth, id).then((res) => {
      const data = res.data;

      setSnackBarOpened(true);
      if (data.success) {
        setSnackBarSeverity("success");
        setSnackBarText("토큰이 성공적으로 회수되었습니다.");
        setHexagramPoint(data.hexagramPoint);
        getHexagramList();
      } else {
        setSnackBarSeverity("error");
        setSnackBarText("토큰 회수에 실패하였습니다. 다시 시도해주세요.");
      }
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "프로젝트 이름",
      flex: 2,
      renderCell: (params) => {
        if (!params.value)
          return (
            <Link
              to={`/hexagram/edit/${params.id}`}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <Typography color="error" sx={{ fontFamily: "Pretendard", fontWeight: "600" }}>
                수정이 필요한 헥사그램입니다. 여기를 클릭하여 수정을 완료해주세요.
              </Typography>
            </Link>
          );
        return (
          <Tooltip title="진행 페이지 이동" arrow>
            <Link
              to={`/hexagram/manager/${params.id}`}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <Box id={params.id} sx={{ cursor: "pointer", width: "100%", display: "flex" }}>
                <PlayCircleFilledWhiteIcon
                  sx={{ mr: "10px", color: "#9a9a9a", "&:hover": { color: "#ababab" } }}
                />
                <Typography sx={{ fontFamily: "Pretendard", fontWeight: "600", color: "#222" }}>
                  {params.value}
                </Typography>
              </Box>
            </Link>
          </Tooltip>
        );
      },
    },
    { field: "tokenCount", headerName: "토큰", flex: 0.7 },
    { field: "type", headerName: "타입", flex: 1 },
    { field: "created", headerName: "날짜", flex: 1 },
    {
      field: "action",
      headerName: "",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <>
            <IconButton id={params.id} onClick={handleMenu}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={params.id}
              anchorEl={anchorEl}
              open={anchorEl === null ? false : params.id === anchorEl.id}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  width: "200px",
                  boxShadow: "1px 1px 2px #00000020",
                  padding: "0 10px",
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem onClick={() => reclaimTokens(params.id)} sx={{ mb: "5px" }}>
                <UndoIcon sx={{ mr: "10px" }} />
                <Typography sx={{ font: "600 1rem Pretendard" }}>토큰 회수하기</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => handleAddTokensModal(params.id)}
                sx={{ mb: "5px" }}
                id={params.id}
              >
                <AddIcon sx={{ mr: "10px" }} />
                <Typography sx={{ font: "600 1rem Pretendard" }}>토큰 확인 및 추가하기</Typography>
              </MenuItem>
              <MenuItem onClick={handleDeleteHexagramModal} sx={{ mb: "5px" }} id={params.id}>
                <DeleteForeverOutlinedIcon sx={{ mr: "10px" }} />
                <Typography sx={{ font: "600 1rem Pretendard" }}>삭제</Typography>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (!isAddModalOpened) setSelectedGame(null);
  }, [isAddModalOpened]);

  useEffect(() => {
    getHexagramList();
  }, []);

  return (
    <S.Body>
      <S.Content>
        <Title children={{ name: "Live Hexagram" }} />
        <UserInfo />
        <S.MyListBox>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ font: "bold 25px Pretendard" }}>My List</Typography>
            <Button
              sx={{ font: "600 18px Pretendard", py: "10px" }}
              onClick={checkedList.length > 0 ? handleDeleteHexagramModal : handleAddHexagramModal}
            >
              {checkedList.length > 0 ? "삭제" : "추가"}
            </Button>
          </Box>
          <S.MyList>
            <DataGrid
              columns={columns}
              rows={lists}
              rowsPerPageOptions={[100]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={handleCheckList}
              selectionModel={checkedList}
              disableColumnMenu
              sx={{
                font: "600 16px Pretendard",
                bgcolor: "#fff",
                borderRadius: "15px",
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "created", sort: "desc" }],
                },
              }}
            />
          </S.MyList>
        </S.MyListBox>
      </S.Content>

      <SnackBar
        open={snackBarOpened}
        onClose={handleSnackBar}
        severity={snackBarSeverity}
        text={snackBarText}
      />

      <AddHexagramModal
        open={isAddModalOpened}
        onClose={handleAddHexagramModal}
        selectedGame={selectedGame}
        getList={getHexagramList}
        hexagramPoint={hexagramPoint}
        setHexagramPoint={setHexagramPoint}
      />

      <AddTokensModal
        open={isAddTokensModalOpened}
        onClose={() => setAddTokensModalOpened(false)}
        hexagramId={currentHexagramId}
        getList={getHexagramList}
        hexagramPoint={hexagramPoint}
        setHexagramPoint={setHexagramPoint}
      />

      {deleteHexagramModalOpened && (
        <DeleteHexagramModal
          open={deleteHexagramModalOpened}
          handleModal={handleDeleteHexagramModal}
          deleteHexagram={deleteHexagram}
          count={checkedList.length}
          id={modalId}
        />
      )}
    </S.Body>
  );
};

export default HexagramListPage;
