/**
 * styled-components를 사용한 스타일 정의
 */
import styled from "styled-components";

/**
 * 문의하기 페이지의 최상위 컨테이너 스타일
 */
export const Container = styled.div`
  min-height: 100vh;
  padding: 20px;
  padding-top: 150px;
  background-color: #f5f5f5;
  box-sizing: border-box;

  > p.title {
    font: 800 40px Pretendard;
    text-align: center;
    color: #333;
  }

  > p.sub-title {
    font: 600 20px Pretendard;
    text-align: center;
    color: #333;
    margin-top: 20px;
    word-break: keep-all;
  }

  > div.contact-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px auto;
    margin-top: 20px;
    max-width: 1280px;
    background-color: #fff;

    > section {
      width: 50%;
      min-width: 300px;
      //   background-color: #ff000050;
      box-sizing: border-box;
      padding: 20px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;

/**
 * 문의 양식 섹션의 스타일
 */
export const FormSection = styled.section`
  > p.title {
    font: 800 24px Pretendard;
    color: #333;
    border-left: 4px solid #777;
    padding-left: 10px;
    padding-top: 5px;

    > span {
      font: 400 16px Pretendard;
      color: #777;
      word-break: keep-all;
      display: block;
      margin-top: 10px;
    }
  }

  > div.item-list {
    margin: 20px 0;

    > div.check-box-item {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    > textarea {
      width: calc(100% - 80px);
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-left: 40px;
      background-color: #f5f5f5;
      font: 500 14px Pretendard;
      resize: none;
      min-height: 50px;
      max-height: 200px;
      height: auto;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  > .term-box {
    margin-top: 20px;

    > p.title {
      font: 600 16px Pretendard;
      color: #333;
      margin-bottom: 3px;

      > span {
        font: 400 18px Pretendard;
        color: #f00;
      }
    }
  }

  > div.button-box {
    margin-top: 20px;

    > button {
      width: calc(100% - 20px);
      padding: 10px;
      border: none;
      border-radius: 5px;
      font: 600 18px Pretendard;
      color: #fff;
      background-color: #c33c3c;
      cursor: pointer;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: #a33c3c;
      }

      &:active {
        transform: scale(0.98);
      }

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
        box-shadow: none;

        &:active {
          transform: none;
        }
      }
    }
  }
`;

/**
 * 체크박스 아이템의 스타일
 */
export const CheckBoxItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  > label {
    font: 600 16px Pretendard;
    color: #333;
    cursor: pointer;
    user-select: none;
  }
`;

/**
 * 정보 입력 박스의 스타일
 */
export const InfoInputBox = styled.div`
  display: block;
  margin-top: 20px;

  > div > input,
  > div > textarea {
    width: calc(100% - 40px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    margin-bottom: 10px;
    font: 500 14px Pretendard;
  }

  p.title {
    font: 600 16px Pretendard;
    color: #333;
    margin-bottom: 3px;

    > span {
      font: 400 18px Pretendard;
      color: #f00;
    }
  }
`;

/**
 * 약관 박스의 스타일
 */
export const TermBox = styled.div`
  width: calc(100% - 40px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 150px;
  overflow-y: auto;
`;

/**
 * 연락처 정보 섹션의 스타일
 */
export const ContactInfo = styled.section`
  > p.title {
    border-left: 4px solid #777;
    padding-left: 10px;
    font: 800 24px Pretendard;
    color: #333;

    > span {
      font: 400 16px Pretendard;
      color: #777;
      display: block;
      margin-top: 10px;
    }
  }
`;
