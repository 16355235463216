import React, { useEffect, useState } from "react";

import { Checkbox, Divider } from "@mui/material";

import * as SC from "./styles";
import { Term, CHECK_BOX_ITEMS } from "./constants";
import { Footer } from "components/Footer";

import * as API_CONTACT from "utils/api/Contact.js";

/**
 * 전체 레이아웃을 구성하는 컴포넌트
 * @description 헤더, 문의하기 페이지, 푸터를 포함한 전체 레이아웃을 구성합니다.
 * @returns {JSX.Element} 헤더, 문의하기 페이지, 푸터를 포함한 전체 레이아웃
 */
const Layout = () => {
  return (
    <>
      <ContactPage />
      <Footer />
    </>
  );
};

const ContactPage = () => {
  /**
   * @state {string[]} checkedItems - 체크된 항목들의 ID 배열
   * @state {string} other - 기타 입력 텍스트
   * @state {string} name - 이름 입력값
   * @state {string} company - 소속 입력값
   * @state {string} phone - 연락처 입력값
   * @state {string} email - 이메일 입력값
   * @state {string} content - 문의 내용 입력값
   * @state {boolean} isAgree - 개인정보 동의 여부
   * @state {boolean} buttonDisabled - 제출 버튼 활성화 여부
   */
  const [checkedItems, setCheckedItems] = useState([]);
  const [other, setOther] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");
  const [isAgree, setIsAgree] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  /**
   * textarea 높이 자동 조절 핸들러
   * @description textarea의 내용에 따라 높이를 자동으로 조절합니다.
   * @param {Event} e - 입력 이벤트 객체
   */
  const handleResizeHeight = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  /**
   * 폼 제출 핸들러
   * @description 폼 데이터를 서버에 전송하고 결과를 처리합니다.
   */
  const handleSubmit = () => {
    setButtonDisabled(true);

    let checkedItemList = [];

    if (checkedItems.includes("all")) {
      checkedItemList = checkedItems.filter((item) => item !== "all");
    } else {
      checkedItemList = checkedItems;
    }

    API_CONTACT.sendContact({
      name,
      company,
      phone,
      email,
      content,
      isAgree,
      other,
      checkedItems: checkedItemList,
    })
      .then((res) => {
        alert("문의가 전달되었습니다. 빠른 시간 내 담당자가 연락드리겠습니다.");
      })
      .catch(() => {
        alert("문의 전달에 실패했습니다. 잠시 후 다시 시도해주세요.");
        setButtonDisabled(false);
      });
  };

  /**
   * 필수 입력값 검증을 위한 useEffect
   * @description 필수 입력값들의 유효성을 검사하여 제출 버튼의 활성화 여부를 결정합니다.
   */
  useEffect(() => {
    if (checkedItems.length > 0 && name && company && phone && email && isAgree) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [checkedItems, name, company, phone, email, isAgree]);

  return (
    <SC.Container>
      <p className="title">연수 신청 · 문의</p>

      <p className="sub-title">연수 신청을 원하시는 분은 아래의 양식을 작성해주세요.</p>

      <div className="contact-form">
        <SC.FormSection>
          <p className="title">
            연수 신청 <br />
            <span>아래 양식을 작성 후 제출해 주시면 빠르게 연락드리겠습니다.</span>
          </p>

          <div className="item-list">
            {CHECK_BOX_ITEMS.map((item) => (
              <CheckBoxItem
                key={item.id}
                item={item}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
              />
            ))}

            <CheckBoxItem
              item={{ id: "other", label: "기타" }}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
            />

            <textarea
              placeholder="직접 입력"
              onInput={handleResizeHeight}
              value={other}
              onChange={(e) => setOther(e.target.value)}
              disabled={!checkedItems.includes("other")}
            />
          </div>

          <Divider />

          <SC.InfoInputBox>
            <div>
              <p className="title">
                이름 <span>*</span>
              </p>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div>
              <p className="title">
                소속 <span>*</span>
              </p>
              <input type="text" value={company} onChange={(e) => setCompany(e.target.value)} />
            </div>
            <div>
              <p className="title">
                연락처 <span>*</span>
              </p>
              <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </div>
            <div>
              <p className="title">
                이메일 <span>*</span>
              </p>
              <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
              <p className="title">문의 내용</p>
              <textarea
                placeholder="문의 내용"
                onInput={handleResizeHeight}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          </SC.InfoInputBox>

          <Divider />

          <div className="term-box">
            <p className="title">
              개인정보 수집 및 이용 동의
              <span>*</span>
            </p>

            <SC.TermBox>
              <Term />
            </SC.TermBox>

            <div className="checkbox-box">
              <Checkbox checked={isAgree} onChange={() => setIsAgree(!isAgree)} />
              <label>개인정보 수집 및 이용에 동의합니다.</label>
            </div>
          </div>

          <div className="button-box">
            <button onClick={handleSubmit} disabled={buttonDisabled}>
              제출
            </button>
          </div>
        </SC.FormSection>

        <SC.ContactInfo>
          <p className="title">
            Contact us
            <span>TEL: 010-9185-4979</span>
            <span>E-MAIL: live@gamedu.co.kr</span>
          </p>

          <p className="sub-title"></p>
        </SC.ContactInfo>
      </div>
    </SC.Container>
  );
};

const CheckBoxItem = ({ item, checkedItems, setCheckedItems }) => {
  /**
   * 체크박스 클릭 핸들러
   * @description 체크박스 클릭 시 체크 상태를 토글합니다.
   */
  const handleClick = () => {
    if (item.id === "all") {
      setCheckedItems(checkedItems.includes(item.id) ? [] : CHECK_BOX_ITEMS.map((item) => item.id));
    } else if (checkedItems.includes(item.id)) {
      setCheckedItems(checkedItems.filter((id) => id !== item.id));
    } else {
      setCheckedItems([...checkedItems, item.id]);
    }
  };

  return (
    <SC.CheckBoxItem>
      <Checkbox checked={checkedItems.includes(item.id)} onChange={handleClick} />
      <label onClick={handleClick}>{item.label}</label>
    </SC.CheckBoxItem>
  );
};

export default Layout;
