import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// import mobileImg from "../assets/images/mobile_img.png";
import mobileImg from "assets/images/mainCharacter1.png";

function MobileAccessRestricted(props) {
  const navigate = useNavigate();

  const [image, setImage] = useState(null);

  useEffect(() => {
    const image = new Image();

    image.src = mobileImg;

    image.onload = () => {
      setImage(image);
    };
  }, []);
  return (
    <div className="MobileAccessRestricted">
      {image && (
        <div
          style={{
            height: "auto",
            width: "auto",
            fontFamily: "NotoSansKR-Medium",
            fontSize: "1.1rem",
            color: "#191919",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ width: "60%" }}>
            <img src={image?.src} />
          </div>

          <p style={{ font: "700 17px Pretendard" }}>위드플러스 라이브에 오신것을 환영합니다!</p>

          <p
            style={{
              font: "600 16px Pretendard",
              textAlign: "center",
              lineHeight: "1.5",
              color: "#191919",
            }}
          >
            위드플러스 라이브 콘텐츠를 이용하시려면
            <br />
            PC 환경에서 접속해주세요.
          </p>

          <button
            style={{
              backgroundColor: "#c33c3c",
              border: "none",
              padding: "10px 20px",
              borderRadius: "10px",
              font: "600 16px Pretendard",
              boxShadow: "0px 3px 6px #00000028",
              color: "white",
            }}
            onClick={() => {
              // navigate("/");
              window.location.replace("/");
            }}
          >
            위드플러스 라이브 홈페이지 바로가기
          </button>
        </div>
      )}
    </div>
  );
}

export default MobileAccessRestricted;
