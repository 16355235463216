import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import heic2any from "heic2any";
import { v4 as uuidv4 } from "uuid";

import { PdfViewer } from "@naverpay/react-pdf";

import {
  Modal,
  Box,
  IconButton,
  Typography,
  Divider,
  Button,
  InputAdornment,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import LinkIcon from "@mui/icons-material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DownloadIcon from "@mui/icons-material/Download";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { ManagerContext } from "views/LiveBoard/contexts/Manager";
import { Textfield } from "components/Textfield";
import { SnackBarContext } from "components/SnackBar/ContextAPI";
import { CustomLoadingButton as LoadingButton } from "components/Button";

import webIcon from "assets/images/webIcon.png";

import * as API from "utils/api/Board";
import axios from "axios";

const baseURL = process.env.REACT_APP_LIVE_BOARD_SERVER_URL;
const BOARD_CLOUDFRONT_URL = process.env.REACT_APP_LIVE_BOARD_CLOUDFRONT_URL;

export const CardModal = (props) => {
  const { open, onClose } = props;
  const { gameId } = useParams();
  const { selectedCard, layout, list, setSelectedCard, sections, socket } =
    useContext(ManagerContext);
  const SnackBar = useContext(SnackBarContext);
  const ref = useRef(null);

  const [time] = useState(new Date());

  const [opacity, setOpacity] = useState(0);
  const [postList, setPostList] = useState([]);
  const [listCount, setListCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [comment, setComment] = useState("");
  const [replyId, setReplyId] = useState(null);
  const [src, setSrc] = useState(null);

  const [file, setFile] = useState(undefined);
  const [renderType, setRenderType] = useState(null);
  const [iframeOpacity, setIframeOpacity] = useState(0);

  const [replyHeight, setReplyHeight] = useState("0vh");

  const selectBackCard = () => {
    setFile(null);
    setSelectedCard(postList[currentIndex - 1]);
    setCurrentIndex(currentIndex - 1);
  };

  const selectForwardCard = () => {
    setFile(null);
    setSelectedCard(postList[currentIndex + 1]);
    setCurrentIndex(currentIndex + 1);
  };

  const addComment = () => {
    if (!comment) return;
    if (comment.trim() === "") {
      setComment("");
      return;
    }

    if (replyId !== null) {
      socket.emit(
        "addReply",
        {
          gameId,
          postId: selectedCard.id,
          comment,
          user: "진행자",
          commentId: replyId,
        },
        (res) => {
          if (res.success) {
            setComment("");
            setReplyId(null);

            SnackBar.setOpen(true);
            SnackBar.setSeverity("success");
            SnackBar.setText("답글이 등록되었습니다.");
          }
        }
      );
      return;
    }

    socket.emit(
      "addComment",
      { gameId, postId: selectedCard.id, comment, user: "진행자" },
      (res) => {
        if (res.success) {
          setComment("");

          SnackBar.setOpen(true);
          SnackBar.setSeverity("success");
          SnackBar.setText("댓글이 등록되었습니다.");
        }
      }
    );
  };

  const handleReply = (e) => {
    // console.log("reply");
    setReplyId(e.currentTarget.id);
    // ref.current.focus();
    ref.current.click();
  };

  const downloadFile = () => {
    const type = selectedCard.file.type;

    API.downloadFile(
      { gameId, fileId: selectedCard.id, type, fileName: selectedCard.file.name },
      (res) => {
        // const { success } = res.data;

        // if (!success) {
        //   alert("파일 다운로드에 실패했습니다.");
        //   window.location.reload();
        //   return;
        // }
        try {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const a = document.createElement("a");

          a.href = url;
          a.download = selectedCard.file.name;
          a.click();

          a.remove();
        } catch (error) {
          console.error(error);
          alert("파일 다운로드에 실패했습니다.");
          window.location.reload();
        }
      }
    );
  };

  const handleReplyHeight = () => {
    setReplyHeight((prev) => (prev === "0vh" ? "30vh" : "0vh"));
  };

  useEffect(() => {
    if (open) {
      setOpacity(1);
    } else {
      setOpacity(0);
    }
  }, [open]);

  useEffect(() => {
    if (layout.section) {
      const sectionPost = list.filter((item) => item.section === selectedCard.section);

      setListCount(sectionPost.length);
      setCurrentIndex(sectionPost.findIndex((item) => item.id === selectedCard.id));
      setPostList(sectionPost);
    } else {
      setListCount(list.length);
      setCurrentIndex(list.findIndex((item) => item.id === selectedCard.id));
      setPostList(list);
    }
  }, []);

  useEffect(() => {
    if (!selectedCard || !selectedCard.file) {
      setFile(null);
      return;
    }

    switch (selectedCard.file.type.toUpperCase()) {
      case "PPTX":
      case "PPT":
      case "XLSX":
      case "XLS":
      case "DOCX":
      case "DOC":
        const fileUrl = `https://www.withplus.live/file?postId=${
          selectedCard.id
        }&gameId=${gameId}&type=${selectedCard.file.type}&filename=${
          selectedCard.file.name
        }&time=${time.getTime()}`;
        // const fileUrl = `https://local.cgcloud.kr/file?postId=${selectedCard.id}&gameId=${gameId}&type=${selectedCard.file.type}&filename=${selectedCard.file.name}`;
        const encodedUrl = encodeURIComponent(fileUrl); // URL 인코딩

        const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`;

        setFile({ name: selectedCard.file.name, src: viewerUrl, type: selectedCard.file.type });
        setRenderType("iframe");
        break;

      case "PDF":
      case "HWP":
      case "HWPX":
        const pdfUrl = `https://www.withplus.live/file?postId=${selectedCard.id}&gameId=${gameId}&type=${selectedCard.file.type}&filename=${selectedCard.file.name}`;

        setFile({ name: selectedCard.file.name, src: pdfUrl, type: selectedCard.file.type });
        setRenderType("pdf");
        break;

      default:
        setRenderType("image");
        setFile({
          name: selectedCard.file.name,
          src: `${BOARD_CLOUDFRONT_URL}/${gameId}/${selectedCard.id}/thumbnail.webp?v=${
            selectedCard.version || 0
          }`,
          type: selectedCard.file.type,
        });
        // fetch(`${baseURL}/live-board/thumbnail?id=${selectedCard.id}&gameId=${gameId}`).then(
        //   (res) => {
        //     res.blob().then((blob) => {
        //       const url = URL.createObjectURL(blob);

        //       // setSrc(url);
        //       setFile({ name: selectedCard.file.name, src: url, type: selectedCard.file.type });
        //       setRenderType("image");
        //     });
        //   }
        // );
        break;
    }

    // fetch(`${baseURL}/live-board/thumbnail?id=${selectedCard.id}&gameId=${gameId}`).then((res) => {
    //   res.blob().then((blob) => {
    //     const url = URL.createObjectURL(blob);

    //     setSrc(url);
    //   });
    // });
  }, [selectedCard]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      disablePortal
      slotProps={{ backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0)" } } }}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
          height: "100%",
          opacity,
          transition: "opacity .5s",
          position: "relative",
        }}
      >
        <IconButton onClick={onClose} sx={{ position: "absolute", top: 10, left: 10 }}>
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            position: "absolute",
            top: 5,
            left: "50%",
            transform: "translate(-50%, 0%)",
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <IconButton onClick={selectBackCard} disabled={currentIndex === 0}>
            <ArrowBackIosIcon />
          </IconButton>

          <Typography sx={{ font: "600 24px Pretendard" }}>{`${
            currentIndex + 1
          } / ${listCount}`}</Typography>

          <IconButton onClick={selectForwardCard} disabled={currentIndex === listCount - 1}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>

        {file && !selectedCard.link && selectedCard.file && localStorage.getItem("UUID_ADM") && (
          <Box
            sx={{
              position: "absolute",
              color: "#fff",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              bgcolor: "#aaa",
              borderRadius: "10px",
              p: "10px",
              width: "180px",
              cursor: "pointer",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
              top: "10px",
              left: "75px",
              "&:hover": {
                bgcolor: "#999",
              },
            }}
            onClick={downloadFile}
          >
            <DownloadIcon />

            <Typography
              sx={{ font: "600 20px Pretendard", textShadow: "0 2px 3px rgba(0, 0, 0, 0.3)" }}
            >
              첨부파일 다운로드
            </Typography>
          </Box>
        )}

        <Box sx={{ height: "100%", display: "flex" }}>
          <Box
            sx={{
              width: file ? "70%" : "30%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: "60px",
              pb: "10px",
              boxSizing: "border-box",
            }}
          >
            {file && renderType === "iframe" && (
              <Box
                sx={{
                  width: "calc(100% - 40px)",
                  height: "calc(100% - 20px)",
                  position: "relative",

                  "& iframe": {
                    position: "absolute",
                    opacity: iframeOpacity,
                    width: "100%",
                    height: "100%",
                    zIndex: 2,
                  },

                  "& > div": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                  },
                }}
              >
                <iframe
                  src={file.src}
                  onLoad={() => {
                    setIframeOpacity(1);
                  }}
                  onError={() => {
                    console.log("error");
                  }}
                />

                <Box>
                  <CircularProgress />
                </Box>
              </Box>
            )}

            {file && renderType === "pdf" && (
              <Box
                sx={{
                  position: "relative",
                  width: "calc(100% - 20px)",
                  height: "calc(100% - 20px)",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                    zIndex: 1,
                  }}
                >
                  <PdfViewer pdfUrl={file.src} />
                </Box>

                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 0,
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            )}

            {file && renderType === "image" && (
              <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
                <img
                  src={file.src}
                  alt={file.name}
                  style={{ height: "100%", objectFit: "contain" }}
                />
              </Box>
            )}

            {!file && (
              <Box>
                <Typography sx={{ font: "700 28px Pretendard" }}>이미지가 없습니다.</Typography>
              </Box>
            )}

            {selectedCard.link && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "10px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ display: "flex", gap: "10px", font: "700 20px Pretendard" }}
                  onClick={() => window.open(selectedCard.link)}
                >
                  <OpenInNewIcon /> 링크로 이동하기
                </Button>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              width: file ? "30%" : "70%",
              height: "100%",
              bgcolor: "#eee",
              pt: "70px",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              px: "10px",
            }}
          >
            {layout.section && (
              <Typography sx={{ font: "600 18px Pretendard", color: "#777" }}>
                {"그룹 : " + sections.find((item) => item.id === selectedCard.section)?.name}
              </Typography>
            )}

            <Typography variant="h4" sx={{ font: "700 28px Pretendard", wordBreak: "break-all" }}>
              {selectedCard.title}
            </Typography>

            {selectedCard.link && (
              <Typography
                sx={{
                  wordBreak: "break-all",
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                  pr: "10px",
                }}
                onClick={() => window.open(selectedCard.link)}
              >
                {selectedCard.link}
              </Typography>
            )}

            {selectedCard.file && !selectedCard.link && (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  color: "#777",
                  // cursor: "pointer",
                }}
                // onClick={downloadFile}
              >
                <AttachFileIcon sx={{ color: "#777" }} />

                <Typography sx={{ wordBreak: "break-all", pr: "10px" }}>
                  첨부파일 : {selectedCard.file.name}
                </Typography>
              </Box>
            )}

            <Box sx={{ flex: 1, position: "relative" }}>
              <Typography
                sx={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-all",
                  font: "600 20px Pretendard",
                  overflow: "auto",
                }}
              >
                {selectedCard.explain}
              </Typography>
            </Box>

            <Divider sx={{ mx: "10px", width: "calc(100% - 20px)", borderColor: "#ccc" }}>
              <Button sx={{ bgcolor: "#ddd", p: 0 }} onClick={handleReplyHeight}>
                <KeyboardArrowUpIcon
                  sx={{
                    transform: replyHeight === "0vh" ? "rotate(0deg)" : "rotate(180deg)",
                    transition: "transform .5s",
                  }}
                />
              </Button>
            </Divider>

            <Box>
              <Box
                sx={{
                  px: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxSizing: "border-box",
                }}
              >
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <FavoriteIcon sx={{ color: "#c33c3c" }} />
                  <Typography sx={{ font: "600 20px Pretendard" }}>
                    {selectedCard.likes?.length || 0}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", gap: "5px", alignItems: "center", pr: "5px" }}>
                  <ChatBubbleOutlineIcon fontSize="large" sx={{ scale: "0.8", color: "#555" }} />
                  <Typography sx={{ font: "600 20px Pretendard" }}>
                    {selectedCard.comments?.length || 0}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  bgcolor: "#fff",
                  borderRadius: "10px",
                  border: "3px solid #c33c3c",
                  mb: "5px",
                }}
              >
                <Textfield
                  fullWidth
                  size="small"
                  placeholder="댓글 추가"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <Button sx={{ fontWeight: "600" }} onClick={addComment}>
                        등록
                      </Button>
                    ),
                    startAdornment: replyId !== null && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton sx={{ fontSize: "12px" }} onClick={() => setReplyId(null)}>
                          X
                        </IconButton>

                        <Typography
                          noWrap
                          sx={{
                            pr: "5px",
                            color: "#c33c3c",
                            fontSize: "12px",
                            fontWeight: 600,
                            maxWidth: "100px",
                          }}
                        >
                          {`@${
                            selectedCard.comments.find((item) => item.date + "" === replyId + "")
                              ?.user
                          }`}
                        </Typography>
                      </Box>
                    ),
                    style: { paddingRight: "0", paddingLeft: "3px" },
                    ref: ref,
                  }}
                />
              </Box>

              <Box
                sx={{
                  p: replyHeight === "0vh" ? "0px" : "10px",
                  height: replyHeight,
                  transition: "height .5s",
                  overflow: replyHeight === "0vh" ? "hidden" : "auto",
                }}
              >
                {selectedCard.comments?.map((item, index) => (
                  <Box
                    sx={{
                      bgcolor: index % 2 === 0 ? "#fff" : "#f5f5f5",
                      borderRadius: "10px",
                      p: "10px",
                      mb: "10px",
                      border: replyId === item.date + "" ? "3px solid #c33c3c" : "",
                    }}
                    key={index}
                  >
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Typography sx={{ font: "600 20px Pretendard", mb: "10px", flex: 1 }}>
                        {item.user}
                      </Typography>

                      <Typography sx={{ font: "500 14px Pretendard", color: "#777", pt: "6px" }}>
                        {(() => {
                          const date = new Date(item.date);
                          const now = new Date();

                          const diff = now - date;

                          if (diff < 1000 * 60) {
                            return "방금 전";
                          }

                          if (diff < 1000 * 60 * 60) {
                            return `${Math.floor(diff / 1000 / 60)}분 전`;
                          }

                          if (diff < 1000 * 60 * 60 * 24) {
                            return `${Math.floor(diff / 1000 / 60 / 60)}시간 전`;
                          }

                          if (diff < 1000 * 60 * 60 * 24 * 7) {
                            return `${Math.floor(diff / 1000 / 60 / 60 / 24)}일 전`;
                          }

                          if (diff < 1000 * 60 * 60 * 24 * 30) {
                            return `${Math.floor(diff / 1000 / 60 / 60 / 24 / 7)}주 전`;
                          }

                          if (diff < 1000 * 60 * 60 * 24 * 365) {
                            return `${Math.floor(diff / 1000 / 60 / 60 / 24 / 30)}개월 전`;
                          }

                          return `${date.getFullYear()}년 ${
                            date.getMonth() + 1
                          }월 ${date.getDate()}일`;
                        })()}
                      </Typography>
                    </Box>

                    <Typography
                      sx={{
                        font: "400 18px Pretendard",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-all",
                      }}
                    >
                      {item.comment}
                    </Typography>

                    <Button
                      sx={{
                        p: 0,
                        color: "#888",
                        fontWeight: "600",
                        pt: "5px",
                        display: "block",
                        minWidth: "0px",
                      }}
                      onClick={handleReply}
                      id={item.date}
                    >
                      답글 달기
                    </Button>

                    {item.replies && (
                      <Box>
                        <Divider sx={{ mt: "10px", borderColor: "#ddd" }} />

                        {item.replies.map((reply, index) => (
                          <Box
                            sx={{
                              borderRadius: "10px",
                              pt: "5px",
                              pl: "10px",
                              mb: "5px",
                            }}
                            key={index}
                          >
                            <Box sx={{ display: "flex", gap: "10px" }}>
                              <Typography sx={{ font: "600 16px Pretendard", mb: "10px", flex: 1 }}>
                                {reply.user}
                              </Typography>

                              <Typography
                                sx={{ font: "500 12px Pretendard", color: "#777", pt: "6px" }}
                              >
                                {(() => {
                                  const date = new Date(reply.date);
                                  const now = new Date();

                                  const diff = now - date;

                                  if (diff < 1000 * 60) {
                                    return "방금 전";
                                  }

                                  if (diff < 1000 * 60 * 60) {
                                    return `${Math.floor(diff / 1000 / 60)}분 전`;
                                  }

                                  if (diff < 1000 * 60 * 60 * 24) {
                                    return `${Math.floor(diff / 1000 / 60 / 60)}시간 전`;
                                  }

                                  if (diff < 1000 * 60 * 60 * 24 * 7) {
                                    return `${Math.floor(diff / 1000 / 60 / 60 / 24)}일 전`;
                                  }

                                  if (diff < 1000 * 60 * 60 * 24 * 30) {
                                    return `${Math.floor(diff / 1000 / 60 / 60 / 24 / 7)}주 전`;
                                  }

                                  if (diff < 1000 * 60 * 60 * 24 * 365) {
                                    return `${Math.floor(diff / 1000 / 60 / 60 / 24 / 30)}개월 전`;
                                  }

                                  return `${date.getFullYear()}년 ${
                                    date.getMonth() + 1
                                  }월 ${date.getDate()}일`;
                                })()}
                              </Typography>
                            </Box>

                            <Typography
                              sx={{
                                font: "400 14px Pretendard",
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-all",
                              }}
                            >
                              {reply.comment}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export const DeleteModal = (props) => {
  const { open, onClose, targetId } = props;
  const { gameId } = useParams();
  const { socket } = useContext(ManagerContext);
  const SnackBar = useContext(SnackBarContext);

  const [loading, setLoading] = useState(false);

  const deletePost = (e) => {
    e.stopPropagation();
    setLoading(true);

    socket.emit("deletePost", { gameId, postId: targetId }, (res) => {
      if (res.success) {
        onClose();

        SnackBar.setOpen(true);
        SnackBar.setSeverity("success");
        SnackBar.setText("게시물이 삭제되었습니다.");
      }
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      disablePortal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        sx={{
          // width: "100%",
          bgcolor: "#fff",
          // height: "100%",
          // position: "relative",
          borderRadius: "10px",
          p: "30px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ font: "700 28px Pretendard", mb: "20px" }}>
          정말 삭제하시겠습니까?
        </Typography>

        <Typography sx={{ font: "400 18px Pretendard", mb: "30px" }}>
          삭제된 게시물은 복구할 수 없습니다.
        </Typography>

        <Box sx={{ display: "flex", gap: "10px" }}>
          <LoadingButton
            sx={{
              bgcolor: "#aaa",
              color: "#fff",
              fontWeight: "700",
              "&:hover": { bgcolor: "#999" },
            }}
            onClick={onClose}
            loading={loading}
          >
            취소
          </LoadingButton>

          <LoadingButton
            sx={{
              bgcolor: "#f00",
              color: "#fff",
              fontWeight: "700",
              "&:hover": { bgcolor: "#e00" },
            }}
            onClick={deletePost}
            loading={loading}
          >
            삭제
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export const PostModal = (props) => {
  const { open, onClose } = props;

  const { gameId } = useParams();
  const inputRef = useRef(null);

  const { sections, targetSection, socket, modifyTarget, setModifyTarget } =
    useContext(ManagerContext);
  const SnackBar = useContext(SnackBarContext);

  // const [bottom, setBottom] = useState("-470px");
  const [bottom, setBottom] = useState("-80%");

  const [file, setFile] = useState(null);
  const [fileOrientation, setFileOrientation] = useState("landscape");
  const [title, settitle] = useState("");
  const [explain, setExplain] = useState("");
  const [isReadyToUpload, setIsReadyToUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [linkModalOpened, setLinkModalOpened] = useState(false);
  const [link, setLink] = useState("");
  const [linkLoading, setLinkLoading] = useState(false);

  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // modifyTarget이 있는 상태에서 파일을 제거했을 때, boolean
  const [isFileRemovedAtModifyTarget, setIsFileRemovedAtModifyTarget] = useState(false);
  // const [isFileRemoved, setIsFileRemoved] = useState(false);

  const handleLink = (e) => {
    setLink(e.target.value);
  };

  const uploadLink = (e) => {
    if (e) e.preventDefault();

    setLinkLoading(true);
    API.searchLink(link, (res) => {
      const { success, ...data } = res.data;

      if (success) {
        let imageUrl;

        if (data.image) {
          fetch(`/temp/${data.thumbnail}`).then((res) => {
            res.blob().then((blob) => {
              imageUrl = URL.createObjectURL(blob);
              setFile({ name: data.site_name || data.title, src: imageUrl, type: "link" });
            });
          });
        } else {
          imageUrl = webIcon;
          setFile({ name: data.site_name || data.title, src: imageUrl, type: "link" });
        }

        if (!link.includes("http")) {
          setLink("https://" + link);
        }

        if (data.title) {
          settitle(data.title);
        }

        // if (data.description) {
        //   setExplain(data.description);
        // }

        setLinkModalOpened(false);
        setLinkLoading(false);
        setIsFileRemovedAtModifyTarget(true);
      } else {
        alert("링크를 불러오는데 실패했습니다.");
        setLink("");
        setLinkLoading(false);
      }
    });
  };

  const handleLinkModal = () => {
    setLinkModalOpened((prev) => !prev);
  };

  const openFileInput = () => {
    inputRef.current.click();
  };

  const handleFile = (e) => {
    if (!e.target.files[0]) return;

    if (e.target.files[0].size > 20 * 1024 * 1024) {
      // if (e.target.files[0].size > 100 * 1024 * 1024) {
      alert("파일 사이즈가 20MB를 초과했습니다.");
      return;
    }

    const acceptedFileType = [
      "jpg",
      "jpeg",
      "png",
      "ppt",
      "pptx",
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "hwp",
      "hwpx",
      "txt",
      "zip",
      "heic",
      "heif",
      "webp",
    ];
    let fileType = e.target.files[0].name.split(".").pop();
    fileType = fileType.toLowerCase();

    if (!acceptedFileType.includes(fileType)) {
      alert("지원하지 않는 파일 형식입니다.");
      return;
    }

    setUploadLoading(true);

    let file = e.target.files[0];
    let type = file.name.split(".").pop();

    // const reader = new FileReader();

    // if (file.type === "image/heic" || file.type === "image/heif") {
    //   heic2any({
    //     blob: file,
    //     toType: "image/jpeg",
    //     quality: 0.7,
    //   }).then((blob) => {
    //     reader.readAsDataURL(blob);

    //     reader.onload = (e) => {
    //       // setFile({ name: file.name, src: e.target.result });
    //       console.log(e);
    //     };
    //   });
    // } else {
    // switch (type) {
    // case "jpg":
    // case "jpeg":
    // case "png":
    //   reader.readAsDataURL(file);

    //   reader.onload = (e) => {
    //     setFile({ name: file.name, src: e.target.result });
    //   };
    //   break;

    // default:
    // const file
    const formData = new FormData();
    formData.append("file", file);

    let uid = localStorage.getItem("uuid");

    if (!uid) {
      uid = uuidv4();
      localStorage.setItem("uuid", uid);
    }

    // axios
    //   .post(`/convert?name=${file.name}`, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res);
    //   });
    // file.name
    let encodedFileName = encodeURIComponent(file.name);

    axios
      .post(`${baseURL}/upload?name=${encodedFileName}&uid=${uid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 1000 * 40,
      })
      .then((res) => {
        console.log(res);
        const { success, fileName } = res.data;

        if (success) {
          fetch(`/temp/${fileName}`).then((res) => {
            res.blob().then((blob) => {
              // let type = blob.type.split("/")[1];

              setFile({
                name: file.name,
                src: URL.createObjectURL(blob),
                // file: blob,
                size: file.size,
                type,
              });

              setUploadLoading(false);
              setUploadProgress(0);
              setIsFileRemovedAtModifyTarget(true);
              // setIsReadyToUpload(true);
            });
          });
        } else {
          alert("파일 업로드에 실패했습니다. 다시 시도해주세요.");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        alert("파일 업로드에 실패했습니다. 다시 시도해주세요.");
        window.location.reload();
      });

    // return;
    // fetch(`${baseURL}/live-board/thumbnail?type=${type}`).then((res) => {
    //   console.log(res);
    //   res.blob().then((blob) => {
    //     console.log(blob);
    //     const thumbnailReader = new FileReader();
    //     thumbnailReader.readAsDataURL(blob);

    //     thumbnailReader.onload = (e) => {
    //       const thumbnail = e.target.result;

    //       const fileReader = new FileReader();
    //       fileReader.readAsArrayBuffer(file);

    //       fileReader.onload = (e) => {
    //         setFile({
    //           name: file.name,
    //           src: thumbnail,
    //           file: e.target.result,
    //           size: file.size,
    //         });
    //       };
    //       // setFile({ name: file.name, src: e.target.result });
    //     };
    //   });
    // });
    // }

    // reader.readAsDataURL(file);

    // reader.onload = (e) => {
    //   setFile({ name: file.name, src: e.target.result });
    // };
    // }
  };

  const handleImage = (e) => {
    // * 파일 사이즈가 20MB 이하인지 확인
    if (e.target.files[0].size > 20 * 1024 * 1024) {
      alert("파일 사이즈가 20MB를 초과했습니다.");
      return;
    }

    let file = e.target.files[0];

    const reader = new FileReader();

    if (file.type === "image/heic" || file.type === "image/heif") {
      heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.7,
      }).then((blob) => {
        reader.readAsDataURL(blob);

        reader.onload = (e) => {
          setFile({ name: file.name, src: e.target.result });
        };
      });
    } else {
      reader.readAsDataURL(file);

      reader.onload = (e) => {
        setFile({ name: file.name, src: e.target.result });
      };
    }
  };

  const removeFile = () => {
    setFile(null);
    setLink("");
    setFileOrientation("landscape");

    if (modifyTarget) {
      setIsFileRemovedAtModifyTarget(true);
    }
  };

  const handleTitle = (e) => {
    settitle(e.target.value);
  };

  const handleExplain = (e) => {
    setExplain(e.target.value);
  };

  const modifyBoard = () => {
    setLoading(true);

    let uuid = localStorage.getItem("uuid");

    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem("uuid", uuid);
    }

    let board = {
      id: modifyTarget.id,
      title,
      explain,
      section: modifyTarget.section,
    };

    if (file) {
      board.file = { ...file, src: null };

      if (file?.type === "link") {
        board.link = link;
      }
    }

    API.modifyPost({ gameId, board, fileModified: isFileRemovedAtModifyTarget, uuid }, (res) => {
      const { success } = res.data;

      if (success) {
        onClose();
        setLoading(false);

        SnackBar.setOpen(true);
        SnackBar.setSeverity("success");
        SnackBar.setText("게시물이 수정되었습니다.");
      } else {
        setLoading(false);

        SnackBar.setOpen(true);
        SnackBar.setSeverity("error");
        SnackBar.setText("게시물 수정에 실패했습니다. 다시 시도해주세요.");
      }
    });

    // setLoading(false);

    // console.log(isFileRemovedAtModifyTarget);
    // console.log(board);

    // setLoading(true);
    // let board;
    // if (file && !file.cors) {
    //   fetch(file.src)
    //     .then((res) => res.blob())
    //     .then((blob) => {
    //       let type = blob.type.split("/")[1];
    //       API.uploadImage(blob, `${modifyTarget.id}.${type}`, (res) => {
    //         const { success } = res.data;
    //         if (success) {
    //           board = {
    //             id: modifyTarget.id,
    //             title,
    //             explain,
    //             file: file ? { name: file.name, src: null } : null,
    //             section: modifyTarget.section,
    //             // password,
    //             link: link === "" ? null : link,
    //           };
    //           socket.emit("modifyBoard", { gameId, board }, (res) => {
    //             console.log(res);
    //             if (res.success) {
    //               onClose();
    //               setLoading(false);
    //               SnackBar.setOpen(true);
    //               SnackBar.setText("게시물이 수정되었습니다.");
    //               SnackBar.setSeverity("success");
    //               setTimeout(() => {
    //                 window.location.reload();
    //               }, 150);
    //             }
    //           });
    //         }
    //       });
    //     });
    // } else if (file && file.cors) {
    //   board = {
    //     id: modifyTarget.id,
    //     title,
    //     explain,
    //     file: file,
    //     section: modifyTarget.section,
    //     link,
    //   };
    //   socket.emit("modifyBoard", { gameId, board, id: modifyTarget.id }, (res) => {
    //     if (res.success) {
    //       onClose();
    //       setLoading(false);
    //       SnackBar.setOpen(true);
    //       SnackBar.setText("게시물이 수정되었습니다.");
    //       SnackBar.setSeverity("success");
    //     }
    //   });
    // } else {
    //   board = {
    //     id: modifyTarget.id,
    //     title,
    //     explain,
    //     file: file ? { name: file.name, src: null } : null,
    //     section: modifyTarget.section,
    //     // password,
    //   };
    //   socket.emit("modifyBoard", { gameId, board }, (res) => {
    //     if (res.success) {
    //       // toggleDrawer();
    //       onClose();
    //       setLoading(false);
    //       SnackBar.setOpen(true);
    //       SnackBar.setText("게시물이 수정되었습니다.");
    //       SnackBar.setSeverity("success");
    //     }
    //   });
    // }
  };

  const postBoard = () => {
    // * 게시물 등록
    console.log("게시물 등록");
    setLoading(true);
    let uuid = localStorage.getItem("uuid");
    const timeId = new Date().getTime();
    let sectionId = targetSection || sections[0].id;
    // let board;

    // console.log(sectionId);
    // return;

    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem("uuid", uuid);
    }

    let board = {
      id: `${uuid}_${timeId}`,
      title,
      explain,
      section: sectionId,
    };

    if (file) {
      board.file = { ...file, src: null };

      if (file?.type === "link") {
        board.link = link;
      }
    }

    // console.log(board);

    // return;

    API.addPost({ gameId, board, uuid }, (res) => {
      const { success } = res.data;

      if (success) {
        onClose();
        setLoading(false);

        SnackBar.setOpen(true);
        SnackBar.setSeverity("success");
        SnackBar.setText("게시물이 등록되었습니다.");
      } else {
        setLoading(false);

        SnackBar.setOpen(true);
        SnackBar.setSeverity("error");
        SnackBar.setText("게시물 등록에 실패했습니다. 다시 시도해주세요.");
      }
    });
    // if (file && !file.cors) {
    //   fetch(file.src)
    //     .then((res) => res.blob())
    //     .then((blob) => {
    //       let type = blob.type.split("/")[1];

    //       API.uploadImage(blob, `${uuid}_${timeId}.${type}`, (res) => {
    //         const { success } = res.data;

    //         if (success) {
    //           board = {
    //             id: `${uuid}_${timeId}`,
    //             title: title,
    //             explain: explain,
    //             file: file ? { name: file.name, src: null } : null,
    //             section: sectionId,
    //             link: link === "" ? null : link,
    //           };

    //           socket.emit("postBoard", { gameId, board }, (res) => {
    //             if (res.success) {
    //               onClose();

    //               SnackBar.setOpen(true);
    //               SnackBar.setSeverity("success");
    //               SnackBar.setText("게시물이 등록되었습니다.");
    //             }
    //           });
    //         }
    //       });
    //     });
    // } else if (file && file.cors) {
    //   board = {
    //     id: `${uuid}_${timeId}`,
    //     title: title,
    //     explain: explain,
    //     file: file,
    //     section: sectionId,
    //     link,
    //   };

    //   socket.emit("postBoard", { gameId, board, id: `${uuid}_${timeId}` }, (res) => {
    //     if (res.success) {
    //       onClose();

    //       SnackBar.setOpen(true);
    //       SnackBar.setSeverity("success");
    //       SnackBar.setText("게시물이 등록되었습니다.");
    //     }
    //   });
    // } else {
    //   board = {
    //     id: `${uuid}_${timeId}`,
    //     title: title,
    //     explain: explain,
    //     file: null,
    //     section: sectionId,
    //   };

    //   socket.emit("postBoard", { gameId, board }, (res) => {
    //     if (res.success) {
    //       onClose();

    //       SnackBar.setOpen(true);
    //       SnackBar.setSeverity("success");
    //       SnackBar.setText("게시물이 등록되었습니다.");
    //     }
    //   });
    // }
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setBottom("0");
      }, 200);
    } else {
      // setBottom("-470px");
      setBottom("-80%");
      setModifyTarget(null);
      setFile(null);
      settitle("");
      setExplain("");
      setLink("");
    }
  }, [open]);

  useEffect(() => {
    if (!file && !title && !explain) {
      setIsReadyToUpload(false);
    } else {
      setIsReadyToUpload(true);
    }

    if (file) {
      const image = new Image();
      image.src = file.src;

      image.onload = () => {
        if (image.width < image.height) {
          setFileOrientation("portrait");
        } else {
          setFileOrientation("landscape");
        }
      };
    }
  }, [file, title, explain]);

  useEffect(() => {
    if (!modifyTarget) return;

    settitle(modifyTarget.title);
    setExplain(modifyTarget.explain);
    setLink(modifyTarget.link);

    if (modifyTarget.file) {
      setFile({
        name: modifyTarget.file.name,
        src: `${BOARD_CLOUDFRONT_URL}/${gameId}/${modifyTarget.id}/thumbnail.webp?v=${
          modifyTarget.version || 0
        }`,
        type: modifyTarget.file.type,
        cors: modifyTarget.file.cors,
      });
      // fetch(`${baseURL}/live-board/thumbnail?id=${modifyTarget.id}&gameId=${gameId}`).then(
      //   (res) => {
      //     res.blob().then((blob) => {
      //       const reader = new FileReader();
      //       reader.readAsDataURL(blob);

      //       reader.onload = (e) => {
      //         setFile({
      //           name: modifyTarget.file.name,
      //           src: e.target.result,ㄹ
      //           type: modifyTarget.file.type,
      //           cors: modifyTarget.file.cors,
      //         });
      //       };
      //     });
      //   }
      // );
    }
  }, [modifyTarget]);

  useEffect(() => {
    if (!socket) return;

    socket.on("uploadProgess", (data) => {
      setUploadProgress(data.progress);
    });

    return () => {
      socket.off("uploadProgess");
    };
  });

  return (
    <Modal open={open} onClose={onClose} sx={{ zIndex: 10001 }} disablePortal>
      <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
        <Box
          sx={{
            bgcolor: "#fff",
            position: "absolute",
            bottom,
            right: "60px",
            width: "600px",
            minHeight: "470px",
            maxHeight: "80%",
            borderRadius: "10px 10px 0 0",
            transition: "bottom 0.2s",
            outline: "none",
            paddingBottom: "20px",
            boxSizing: "border-box",
            overflow: "auto",
            // display: "flex",
            // flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", p: "10px" }}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>

            <LoadingButton
              sx={{ font: "600 18px Pretendard", p: "10px 20px" }}
              disabled={!isReadyToUpload}
              onClick={modifyTarget ? modifyBoard : postBoard}
              loading={loading}
            >
              {modifyTarget ? "수정하기" : "게시하기"}
            </LoadingButton>
          </Box>

          {targetSection && (
            <Typography sx={{ font: "600 18px Pretendard", px: "10px" }}>
              {sections.find((item) => item.id === targetSection)?.name}에 게시물 작성
            </Typography>
          )}

          <Box sx={{ p: "10px" }}>
            <Textfield
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">제목 : </InputAdornment>,
              }}
              value={title}
              onChange={handleTitle}
              placeholder="제목을 입력해주세요."
            />
          </Box>

          <Box
            sx={{
              p: "10px",
              boxSizing: "border-box",
              height: fileOrientation === "landscape" ? "250px" : "400px",
            }}
          >
            <Box
              sx={{
                height: "100%",
                border: "2.5px dashed #e0e0e0",
                p: "10px",
                boxSizing: "border-box",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#dddddd88",
              }}
            >
              {!file && !uploadLoading && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    {/*  */}
                    <Tooltip
                      title="파일 업로드"
                      PopperProps={{ disablePortal: true }}
                      arrow
                      placement="top"
                    >
                      <IconButton
                        sx={{ border: "2px solid #aaa", bgcolor: "#fff" }}
                        onClick={openFileInput}
                      >
                        <UploadFileRoundedIcon sx={{ fontSize: "40px" }} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      title="URL 업로드"
                      PopperProps={{ disablePortal: true }}
                      arrow
                      placement="top"
                      onClick={handleLinkModal}
                    >
                      <IconButton sx={{ border: "2px solid #aaa", bgcolor: "#fff" }}>
                        <LinkIcon sx={{ fontSize: "40px" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Typography sx={{ font: "700 22px Pretendard" }}>파일 / 링크 업로드</Typography>

                  <Typography sx={{ font: "600 14px Pretendard", color: "#777" }}>
                    (파일 업로드는 20MB 이하의 파일만 가능합니다.)
                  </Typography>

                  <input
                    type="file"
                    accept=".jpg, .png, .jpeg, .ppt, .pptx, .pdf, .doc, .docx, .xls, .xlsx, .hwp, .hwpx, .txt, .zip, .heic, .heif, .webp"
                    style={{ display: "none" }}
                    ref={inputRef}
                    onChange={handleFile}
                  />
                </Box>
              )}

              {uploadLoading && (
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      bgcolor: "#fff",
                      p: "10px",
                      borderRadius: "6px",
                      boxShadow: "0px 3px 6px #00000038",
                    }}
                  >
                    <CircularProgress value={uploadProgress} variant="determinate" size={60} />

                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        font: "600 14px Pretendard",
                      }}
                    >
                      {uploadProgress}%
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <Typography sx={{ font: "600 18px Pretendard", mt: "10px" }}>
                      파일 업로드 중...
                    </Typography>

                    <CircularProgress size={20} />
                  </Box>
                </Box>
              )}

              {file && (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    // bgcolor: "#f0f0f088",

                    "> img": {
                      position: "absolute",
                      top: -5,
                      height: "calc(100% + 10px)",
                      objectFit: "contain",
                    },

                    "> button": {
                      position: "absolute",
                      bottom: -5,
                      right: -5,
                      fontWeight: "600",
                    },
                  }}
                >
                  <img src={file.src} alt="file" />

                  <Box
                    sx={{
                      position: "absolute",
                      bottom: -10,
                      left: -10,
                      bgcolor: "#333",
                      p: "5px",
                      borderRadius: "0 6px 0 10px",
                      font: "600 14px Pretendard",
                      color: "#fff",
                    }}
                  >
                    {file.name}{" "}
                    {(() => {
                      if (file.size < 1024) return `(${file.size} B)`;
                      if (file.size < 1024 * 1024) return `(${Math.round(file.size / 1024)} KB)`;
                      if (file.size < 1024 * 1024 * 1024)
                        return `(${Math.round(file.size / 1024 / 1024)} MB)`;
                    })()}
                  </Box>

                  <Button variant="contained" size="small" onClick={removeFile}>
                    제거
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={{ p: "10px" }}>
            <Textfield
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">내용 : </InputAdornment>,
              }}
              multiline
              // maxRows={5}
              placeholder="내용을 입력해주세요."
              value={explain}
              onChange={handleExplain}
            />
          </Box>
        </Box>

        {linkModalOpened && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              bgcolor: "#00000070",
            }}
          >
            <Box
              sx={{
                bgcolor: "#fff",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: " 315px",
                boxSizing: "border-box",
                borderRadius: "10px",
                // display: "flex",
                // gap: "10px",
              }}
              component="form"
              onSubmit={uploadLink}
            >
              <Typography
                sx={{
                  font: "700 18px Pretendard",
                  p: "10px",
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translate(-50%, 0)",
                }}
              >
                링크 업로드
              </Typography>

              <IconButton onClick={handleLinkModal}>
                <CloseIcon />
              </IconButton>

              <Textfield
                placeholder="여기에 링크 붙여넣기 또는 직접 입력"
                sx={{
                  width: "calc(100% - 20px)",
                  border: "2px solid #f00",
                  borderRadius: "10px",
                  margin: "10px",
                  mb: "5px",
                }}
                autoFocus
                size="small"
                value={link}
                onChange={handleLink}
              />

              <LoadingButton
                variant="contained"
                sx={{ font: "600 18px Pretendard", width: "calc(100% - 20px)", margin: "10px" }}
                onClick={uploadLink}
                loading={linkLoading}
              >
                링크 업로드
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
